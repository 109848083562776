import { Collapse, Container, ClickAwayListener, Paper } from "@mui/material";
import { getColorWithMode } from "../constants/colors";
import { useMediaQuery } from "react-responsive";
import GearExploreList from "./GearExploreList";

const GearComparisonSelectorCollapse = ({
  underNavBar,
  darkMode,
  browseGearIsOpen,
  setBrowseGearIsClosed,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  return (
    <Collapse
      in={!browseGearIsOpen}
      sx={{
        marginTop: underNavBar ? (isMobile ? "80px" : "50px") : "0px",
        position: "absolute",
        zIndex: "1300 !important",
        maxWidth: underNavBar ? "1150px" : "100%",
        minWidth: "340px",
      }}
    >
      <ClickAwayListener onClickAway={() => setBrowseGearIsClosed(true)}>
        <Paper
          elevation={3}
          sx={{
            padding: "10px",
            maxWidth: "1118px",
          }}
        >
          <GearExploreList showDeals />
        </Paper>
      </ClickAwayListener>
    </Collapse>
  );
};

export default GearComparisonSelectorCollapse;
