import React from "react";
import {
  Button,
  Collapse,
  TextField,
  Typography,
  Link,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import ReactMarkdown from "react-markdown";
import { useMediaQuery } from "react-responsive";
import { getColorWithMode } from "../constants/colors";
import { updatePack } from "../utils";
import gfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import DOMPurify from "dompurify";

const PackDescription = ({
  packDescription,
  setPackDescription,
  tableId,
  shareable,
  darkMode,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const [expanded, setExpanded] = React.useState(true);
  const [temporaryDescription, setTemporaryDescription] = React.useState(
    packDescription || ""
  );

  const handleUpdatePackDescription = () => {
    const newData = { packDescription: temporaryDescription || "" };
    setPackDescription(temporaryDescription);
    updatePack(tableId, newData);
  };

  React.useEffect(() => {
    setTemporaryDescription(packDescription || "");
  }, [packDescription]);

  const renderContent = () => {
    if (!shareable) {
      return (
        <TextField
          multiline
          variant="standard"
          value={temporaryDescription}
          sx={{
            width: "100%",
            padding: "5px 5px 5px 10px",
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: getColorWithMode(darkMode, "text"),
            },
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: shareable,
            sx: {
              fontSize: isMobile ? "24px" : "16px",
            },
          }}
          inputProps={{
            maxLength: 50000,
          }}
          placeholder="Description"
          onFocus={() => {
            if (!expanded) {
              setExpanded(true);
            }
          }}
          onChange={(e) => {
            if (!expanded) {
              setExpanded(true);
            }
            setTemporaryDescription(e.target.value);
          }}
          onBlur={handleUpdatePackDescription}
        />
      );
    }

    // Sanitize HTML within the content while preserving Markdown
    const sanitizedContent = DOMPurify.sanitize(temporaryDescription, {
      ALLOWED_TAGS: [
        "a",
        "img",
        "p",
        "br",
        "b",
        "i",
        "em",
        "strong",
        "blockquote",
      ],
      ALLOWED_ATTR: ["href", "target", "rel", "src", "alt", "width", "height"],
      ALLOW_DATA_ATTR: false,
      ADD_ATTR: ["target"],
      FORBID_TAGS: ["style", "script", "iframe", "form", "input", "button"],
      FORBID_ATTR: ["onerror", "onload", "onclick", "onmouseover", "style"],
    });

    return (
      <Box
        sx={{
          "& ul": {
            listStyleType: "disc",
            paddingLeft: "2em",
            marginBottom: "1em",
          },
          "& ol": {
            listStyleType: "decimal",
            paddingLeft: "2em",
            marginBottom: "1em",
          },
          "& li": {
            marginBottom: "0.5em",
          },
          "& p": {
            marginBottom: "1em",
          },
          "& h1, & h2, & h3, & h4, & h5, & h6": {
            marginTop: "1em",
            marginBottom: "0.5em",
            fontWeight: "bold",
          },
          "& blockquote": {
            borderLeft: `4px solid ${darkMode ? "#404040" : "#e0e0e0"}`,
            margin: "1em 0",
            padding: "0.5em 1em",
            backgroundColor: darkMode ? "#333333" : "#f5f5f5",
            borderRadius: "0 4px 4px 0",
            "& p": {
              margin: 0,
              color: darkMode ? "#e0e0e0" : "#666666",
            },
          },
          "& blockquote p": {
            marginBottom: 0,
          },
          "& img": {
            maxWidth: "100%",
            height: "auto",
            borderRadius: "8px",
            margin: "16px 0",
          },
          "& a": {
            color: darkMode ? "#90caf9" : "#1976d2",
            textDecoration: "none",
            "&:hover": {
              color: darkMode ? "#42a5f5" : "#1565c0",
              textDecoration: "underline",
            },
          },
        }}
      >
        <ReactMarkdown
          remarkPlugins={[[gfm, { singleTilde: false }]]}
          rehypePlugins={[rehypeRaw]}
          components={{
            a: ({ node, children, href, ...props }) => (
              <Link
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: darkMode ? "#90caf9" : "#1976d2",
                  "&:hover": {
                    color: darkMode ? "#42a5f5" : "#1565c0",
                  },
                }}
                {...props}
              >
                {children}
              </Link>
            ),
            img: ({ node, src, alt, ...props }) => (
              <Box
                component="img"
                src={src}
                alt={alt || "Image"}
                sx={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "8px",
                  margin: "16px 0",
                }}
                {...props}
              />
            ),
            blockquote: ({ node, children, ...props }) => (
              <Box
                component="blockquote"
                sx={{
                  borderLeft: `4px solid ${darkMode ? "#404040" : "#e0e0e0"}`,
                  margin: "1em 0",
                  padding: "0.5em 1em",
                  backgroundColor: darkMode ? "#333333" : "#f5f5f5",
                  borderRadius: "0 4px 4px 0",
                  "& p": {
                    margin: 0,
                    color: darkMode ? "#e0e0e0" : "#666666",
                  },
                }}
                {...props}
              >
                {children}
              </Box>
            ),
          }}
        >
          {sanitizedContent}
        </ReactMarkdown>
      </Box>
    );
  };

  if (!shareable && !temporaryDescription.length) {
    return null;
  }

  return (
    <>
      <Collapse
        in={expanded}
        sx={{
          marginTop: "-5px",
          marginBottom: expanded ? "-15px" : "0px",
        }}
        collapsedSize={40}
      >
        <Box
          sx={{
            borderRadius: "10px",
            backgroundColor: darkMode ? "#2A2A2A" : "#F0F0F0",
            padding: "16px",
          }}
        >
          {renderContent()}
        </Box>
        {!shareable && (
          <Typography variant="subtitle2" sx={{ opacity: "0.8" }}>
            We support both Markdown and{" "}
            <Tooltip
              title="We support a, img, p, br, b, i,
            em and strong tags"
            >
              basic HTML tags
            </Tooltip>
          </Typography>
        )}
      </Collapse>
      {temporaryDescription.length > 0 && (
        <Button
          sx={{
            position: "relative",
            padding: "0px",
            fontSize: isMobile ? "14px" : "12px",
          }}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          {`${expanded ? "collapse" : "expand"} description`}
        </Button>
      )}
    </>
  );
};

export default PackDescription;
