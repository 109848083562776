import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellModes,
  GridRow,
} from "@mui/x-data-grid-pro";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import AddRowForm from "./AddRowForm";
import * as _ from "lodash";
import {
  getWeightInGrams,
  getWeightInUnitFromGrams,
  logErrorOnServer,
} from "../utils";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import WEIGHT_TYPES from "../constants/weightTypes.json";
import DetailPanelContent from "./DetailPanelContent";
import CustomDetailPanelToggle from "./CustomDetailPanelToggle";
import { Link, Stack, Tooltip, Typography } from "@mui/material";
import DEFAULT_CATEGORIES from "../constants/defaultCategories";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Popper from "@mui/material/Popper";
import LinkIcon from "@mui/icons-material/Link";
import { externalLinkWrapper } from "../utils/links";
import { isValidUrl } from "../utils/validationUtils";
import { getColorWithMode } from "../constants/colors";
import { useMediaQuery } from "react-responsive";
import MobileHandleWeightChangeMenu from "./MobileHandleWeightChangeMenu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import { useDrop, useDrag } from "react-dnd";

const UNEDITABLE_ROW_TYPES = ["ADD_ROW", "ADDING_ROW", "DIVIDER"];

const ROW_DRAG_TYPE = "ROW";

const DraggableGridRow = (props) => {
  const { row, tableIndex } = props;

  React.useEffect(() => {
    const handleDragStart = (e) => {
      if (e.target.closest(".MuiDataGrid-rowReorderCell")) {
        const data = {
          rowId: row.id,
          sourceTableIndex: tableIndex,
          rowData: row,
        };

        e.dataTransfer.setData("application/json", JSON.stringify(data));
        e.dataTransfer.setData("text/plain", JSON.stringify(data));
        e.dataTransfer.effectAllowed = "move";
      }
    };

    const rowElement = document.querySelector(`[data-id="${row.id}"]`);
    if (rowElement) {
      rowElement.addEventListener("dragstart", handleDragStart, {
        capture: true,
      });

      return () => {
        rowElement.removeEventListener("dragstart", handleDragStart, {
          capture: true,
        });
      };
    }
  }, [row.id, tableIndex, row]);

  return <GridRow {...props} />;
};

const INITIAL_SUMMARY_ROW = {
  id: "SUMMARY",
  type: "DIVIDER",
  item: "",
  weight: 0,
  unit: "lb",
  price: 0,
};

const baseAddRow = {
  id: "ADD_ROW",
  type: "ADD_ROW",
  item: "",
  weight: 0,
  unit: "g",
  price: 0,
  quantity: 1,
};

const tempAddRow = {
  id: "ADDING_ROW",
  type: "ADDING_ROW",
  item: "",
  weight: 0,
  unit: "g",
  price: 0,
  quantity: 1,
};

const calculateSummaryRow = (rows, summaryRowUnit) => {
  const summaryValues = Object.values(rows).reduce(
    (acc, curr) => {
      const weightInGrams = getWeightInGrams(curr.weight, curr.unit);
      const rowWeight = weightInGrams * curr.quantity;
      return {
        weight: acc.weight + rowWeight,
        price: acc.price + curr.price * (curr.quantity ? curr.quantity : 1),
      };
    },
    {
      weight: 0,
      price: 0,
    }
  );
  const finalValues = {
    weight: getWeightInUnitFromGrams(summaryValues.weight, summaryRowUnit),
    price: summaryValues.price,
    unit: summaryRowUnit,
  };
  return { ...INITIAL_SUMMARY_ROW, ...finalValues };
};

const DataGridContainer = ({
  allTableData,
  tableRows,
  handleSetTableData,
  tableIndex,
  tableWeightType,
  tableId,
  summaryRowUnit,
  darkMode,
  affiliateLinksAreOff,
  packCurrency,
  packSettingsTags,
  handleUpdateTwoTables,
}) => {
  const isMobile = useMediaQuery({ maxWidth: "898px" });
  const gridRef = React.useRef(null);
  const [isAddItemOpen, setIsAddItemOpen] = React.useState(false);
  const [addRow, setAddRow] = React.useState(baseAddRow);
  const [cellModesModel, setCellModesModel] = React.useState({});
  const [{ isOver }, drop] = useDrop({
    accept: ROW_DRAG_TYPE,
    drop: async (item, monitor) => {
      // Handle drop from both our custom drag and MUI's drag
      const droppedItem =
        monitor.getItemType() === ROW_DRAG_TYPE
          ? item
          : JSON.parse(item.dataTransfer.getData("application/json"));

      if (droppedItem.sourceTableIndex === tableIndex) {
        return;
      }

      const clientOffset = monitor.getClientOffset();
      const dropIndex = findDropIndex(clientOffset.y);

      // Create new tableData array
      const newTableData = [...allTableData];
      const newRow = {
        ...item.rowData,
        weightType: tableWeightType,
      };
      // Add row to target table

      // Add row to target table at specific index
      const targetRows = insertRowAtIndex(
        newTableData[tableIndex].rows,
        newRow,
        dropIndex
      );
      newTableData[tableIndex] = {
        ...newTableData[tableIndex],
        rows: targetRows,
      };

      // Remove row from source table
      const sourceRows = { ...newTableData[droppedItem.sourceTableIndex].rows };
      delete sourceRows[droppedItem.rowId];

      // Add row to target table
      const newTableRows = {
        ...tableRows,
        [item.rowId]: {
          ...item.rowData,
          weightType: tableWeightType,
        },
      };

      await handleUpdateTwoTables(
        tableIndex,
        newTableRows,
        item.sourceTableIndex,
        sourceRows
      );
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  // Handle the native drag event
  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    try {
      const data = JSON.parse(e.dataTransfer.getData("application/json"));
      if (data.sourceTableIndex === tableIndex) {
        return;
      }

      // Create new tableData array
      const dropIndex = findDropIndex(e.clientY);
      const newTableData = [...allTableData];

      // Prepare the row to be added
      const newRow = {
        ...data.rowData,
        weightType: tableWeightType,
      };

      // Add to target table at specific index
      const targetRows = insertRowAtIndex(
        newTableData[tableIndex].rows,
        newRow,
        dropIndex
      );

      newTableData[tableIndex] = {
        ...newTableData[tableIndex],
        rows: targetRows,
      };

      // Remove row from source table
      const sourceRows = { ...newTableData[data.sourceTableIndex].rows };
      delete sourceRows[data.rowId];

      await handleUpdateTwoTables(
        tableIndex,
        targetRows,
        data.sourceTableIndex,
        sourceRows
      );
    } catch (err) {
      // Not our drag data, ignore it
      console.log("Not a valid drag operation");
    }
  };

  const findDropIndex = (clientY) => {
    if (!gridRef.current) return 0;

    const rows = gridRef.current.querySelectorAll(".MuiDataGrid-row");
    const rowArray = Array.from(rows);

    // Filter out special rows
    const regularRows = rowArray.filter((row) => {
      const rowId = row.getAttribute("data-id");
      return !UNEDITABLE_ROW_TYPES.includes(tableRows[rowId]?.type);
    });

    for (let i = 0; i < regularRows.length; i++) {
      const row = regularRows[i];
      const rect = row.getBoundingClientRect();
      const rowMiddle = rect.top + rect.height / 2;

      if (clientY < rowMiddle) {
        return i;
      }
    }

    return regularRows.length;
  };

  const insertRowAtIndex = (rows, newRow, targetIndex) => {
    const rowEntries = Object.entries(rows).filter(
      ([_, row]) => !UNEDITABLE_ROW_TYPES.includes(row.type)
    );

    rowEntries.splice(targetIndex, 0, [newRow.id, newRow]);

    // Reconstruct the rows object with special rows preserved
    const newRows = {};
    Object.entries(rows).forEach(([id, row]) => {
      if (UNEDITABLE_ROW_TYPES.includes(row.type)) {
        newRows[id] = row;
      }
    });

    rowEntries.forEach(([id, row]) => {
      newRows[id] = row;
    });

    return newRows;
  };

  const hidePrices =
    packSettingsTags &&
    packSettingsTags.find(({ type, value }) => type === "hidePrices" && value);

  const handleCellClick = React.useCallback((params, event) => {
    if (!params.isEditable || params.field === "category") {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const summaryRow = calculateSummaryRow(tableRows, summaryRowUnit);

  const handleChangeWeightType = (id, weightType) => {
    const rowData = tableRows[id];
    const newWeightType =
      weightType === rowData.weightType ? WEIGHT_TYPES.BASE : weightType;
    const newTableRows = { ...tableRows };
    newTableRows[id].weightType = newWeightType;
    handleSetTableData(tableIndex, "rows", newTableRows);
  };

  const handleAddNewItemToTable = (newItem) => {
    if (!newItem || !newItem.id) {
      return;
    }
    if (newItem.gearClosetId) {
      console.log("added from gear closet");
    }
    handleSetTableData(tableIndex, "rows", {
      ...tableRows,
      [newItem.id]: newItem,
    });
  };

  const handleDeleteRow = (id) => {
    const newData = { ...tableRows };
    delete newData[id];
    handleSetTableData(tableIndex, "rows", newData);
  };

  const handleRowOrderChange = ({ row, oldIndex, targetIndex }) => {
    if (oldIndex === targetIndex) return;

    // TODO: Consider additional row order value in table data
    const keys = Object.keys(tableRows);

    const newKeyOrder = [];
    for (let i = 0; i < keys.length; i++) {
      if (i !== oldIndex) {
        if (oldIndex > targetIndex) {
          if (i === targetIndex) {
            newKeyOrder.push(keys[oldIndex]);
          }
          newKeyOrder.push(keys[i]);
        } else {
          newKeyOrder.push(keys[i]);
          if (i === targetIndex) {
            newKeyOrder.push(keys[oldIndex]);
          }
        }
      }
    }
    const newTableRows = {};
    newKeyOrder.forEach((key) => {
      newTableRows[key] = tableRows[key];
    });

    handleSetTableData(tableIndex, "rows", newTableRows);
  };

  const pinnedRows = {
    bottom: [addRow, summaryRow],
  };

  const getIconColor = (isDisabled, isSelected) => {
    if (isDisabled && isSelected) return "#608da2";
    if (isDisabled) return "grey";
    if (!isDisabled && isSelected) return "#00a2e4";
    return darkMode ? "white" : "#AAA";
  };

  useEffect(() => {
    const newData = { ...tableRows };
    if (tableWeightType !== WEIGHT_TYPES.BASE) {
      Object.keys(newData).forEach((key) => {
        newData[key].weightType = tableWeightType;
      });
      handleSetTableData(tableIndex, "rows", newData);
    }
  }, [tableWeightType]);

  const handleRowUpdate = (rowData) => {
    // recalculate summary row with new unit if that is the update
    const { id } = rowData;
    if (!id) return rowData;
    if (id === "SUMMARY") {
      handleSetTableData(tableIndex, "summaryRowUnit", rowData.unit);
    } else {
      const newRows = tableRows;
      newRows[id] = rowData;
      handleSetTableData(tableIndex, "rows", newRows);
    }
    return rowData;
  };

  const handleRowEditError = (err) => {
    logErrorOnServer(err);
  };

  const columns = [
    ...(isMobile
      ? [
          {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            headerClassName: "lastcolumnSeparator",
            headerAlign: "right",
            width: 65,
            renderCell: (params) => {
              if (UNEDITABLE_ROW_TYPES.includes(params.row.type)) {
                return <></>;
              }
              return (
                <CustomDetailPanelToggle id={params.id} value={params.value} />
              );
            },
          },
        ]
      : []),
    {
      field: "item",
      headerName: "Item",
      minWidth: isMobile ? 207 : 180,
      editable: true,
      headerAlign: "left",
      colSpan: ({ row }) => {
        if (row.type === "DIVIDER") {
          return 1;
        }
        if (row.type === "ADDING_ROW") {
          return 7;
        }
      },
      flex: 1,
      sortable: false,
      headerClassName: "firstcolumnSeparator",
      renderCell: (params) => {
        if (params.id === "ADD_ROW") {
          return <></>;
        }
        if (params.id === "ADDING_ROW") {
          return (
            <AddRowForm
              setIsAddItemOpen={setIsAddItemOpen}
              handleAddNewItemToTable={handleAddNewItemToTable}
              tableWeightType={tableWeightType}
              darkMode={darkMode}
            />
          );
        }
        if (params.id === "SUMMARY") {
          return (
            <Stack sx={{ width: "100%" }}>
              <Button
                className="add-row-button"
                onClick={() => setIsAddItemOpen(true)}
                startIcon={<AddIcon />}
                disabled={isAddItemOpen}
                sx={{ justifyContent: "flex-start" }}
              >
                Add Item
              </Button>
            </Stack>
          );
        }
        return (
          <Stack
            direction="row"
            alignItems="center"
            sx={{ width: "100%" }}
            justifyContent="space-between"
          >
            <Typography
              variant={isMobile ? "h6" : "body2"}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {params.row.item}
            </Typography>
            {isValidUrl(params.row.url) ? (
              <Link
                type="button"
                size="small"
                sx={{
                  color: getColorWithMode(darkMode, "lightText"),
                  "&:hover": { opacity: "0.6", cursor: "pointer" },
                }}
                onClick={(e) => {
                  if (e.key !== "Enter")
                    if (isValidUrl(params.row.url)) {
                      externalLinkWrapper(params.row.url, affiliateLinksAreOff);
                    }
                }}
              >
                <LinkIcon></LinkIcon>
              </Link>
            ) : (
              <></>
            )}
          </Stack>
        );
      },
    },
    {
      field: "category",
      headerName: "Item Type",
      minWidth: isMobile ? 110 : 130,
      editable: true,
      headerAlign: "left",
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <Autocomplete
          freeSolo
          disableClearable
          sx={{
            width: "100%",
            minWidth: "110px",
          }}
          options={DEFAULT_CATEGORIES.map((option) => ({
            id: option,
            label: option,
          }))}
          PopperComponent={(props) => (
            <Popper {...props} sx={{ minWidth: "170px" }} />
          )}
          value={params.row.category}
          onChange={(event, value) => {
            const valueToUpdate = value.label ? value.label : value;
            const newRow = { ...params.row, category: valueToUpdate };
            handleRowUpdate(newRow);
          }}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
          onBlur={(e) => {
            const valueToUpdate = e.target.value;
            const newRow = { ...params.row, category: valueToUpdate };
            handleRowUpdate(newRow);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                style: {
                  fontSize: isMobile ? "20px" : "14px",
                },
              }}
            />
          )}
        />
      ),
    },
    {
      field: "quantity",
      headerName: "Qty",
      type: "number",
      width: 55,
      editable: true,
      headerAlign: "right",
      sortable: false,
    },
    {
      field: "weight",
      headerName: "Weight",
      type: "number",
      width: isMobile ? 110 : 100,
      editable: true,
      headerAlign: "right",
      sortable: false,
      headerClassName: "lastcolumnSeparator",
    },
    {
      field: "unit",
      headerName: "",
      type: "singleSelect",
      width: 67,
      height: "36px",
      valueOptions: ["g", "oz", "lb", "kg"],
      editable: true,
      headerAlign: "left",
      sortable: false,
      renderCell: (params) => {
        if (params.row.id === "SUMMARY") {
          return (
            <Stack direction="row" alignItems="center">
              {params.row.unit}
              <ArrowDropDownIcon />
            </Stack>
          );
        }
        return params.row.unit;
      },
      cellClassName: "selectCell",
    },
    ...(hidePrices
      ? []
      : [
          {
            field: "price",
            headerName: "Price",
            minWidth: 85,
            type: "number",
            editable: true,
            headerAlign: "right",
            sortable: false,
            renderCell: (params) => {
              if (params.row.id === "SUMMARY") {
                try {
                  return (
                    <Tooltip title="You can update your currency in Pack Settings & Analytics">
                      {Number(params.value).toLocaleString("en-US", {
                        style: "currency",
                        currency: packCurrency,
                      })}
                    </Tooltip>
                  );
                } catch (e) {
                  return (
                    <Tooltip title="You can update your currency in Pack Settings & Analytics">
                      {Number(params.value).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </Tooltip>
                  );
                }
              } else {
                return (
                  <Tooltip title="You can update your currency in Pack Settings & Analytics">
                    {Number(params.value).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })}
                  </Tooltip>
                );
              }
            },
          },
        ]),
    {
      field: "action",
      headerName: "",
      width: isMobile ? 60 : 114,
      headerClassName: "lastcolumnSeparator",
      headerAlign: "left",
      sortable: false,
      renderCell: (params) => {
        if (UNEDITABLE_ROW_TYPES.includes(params.row.type)) {
          return <></>;
        }
        const isCategoryBase = tableWeightType === WEIGHT_TYPES.BASE;
        return isMobile ? (
          <MobileHandleWeightChangeMenu
            isCategoryBase={isCategoryBase}
            handleChangeWeightType={handleChangeWeightType}
            params={params}
            weightType={tableRows[params.row.id].weightType}
            darkMode={darkMode}
            handleDeleteRow={handleDeleteRow}
          />
        ) : (
          <>
            <IconButton
              disabled={!isCategoryBase}
              sx={{
                height: isMobile ? "40px" : "30px",
                width: isMobile ? "40px" : "30px",
                marginRight: "7px",
              }}
              onClick={() => {
                handleChangeWeightType(params.row.id, WEIGHT_TYPES.WORN);
              }}
            >
              <Tooltip
                title={
                  tableRows[params.row.id].weightType === WEIGHT_TYPES.WORN
                    ? "This item is marked as worn"
                    : "Mark this item as worn"
                }
              >
                <CheckroomIcon
                  sx={{
                    color: getIconColor(
                      !isCategoryBase,
                      tableRows[params.row.id].weightType === WEIGHT_TYPES.WORN
                    ),
                  }}
                />
              </Tooltip>
            </IconButton>
            <IconButton
              disabled={!isCategoryBase}
              sx={{
                height: isMobile ? "40px" : "30px",
                width: isMobile ? "40px" : "30px",
                marginRight: "7px",
              }}
              onClick={() => {
                handleChangeWeightType(params.row.id, WEIGHT_TYPES.CONSUMABLE);
              }}
            >
              <Tooltip
                title={
                  tableRows[params.row.id].weightType ===
                  WEIGHT_TYPES.CONSUMABLE
                    ? "This item is marked as a consumable"
                    : "Mark this item as a consumable"
                }
              >
                <RestaurantMenuIcon
                  sx={{
                    color: getIconColor(
                      !isCategoryBase,
                      tableRows[params.row.id].weightType ===
                        WEIGHT_TYPES.CONSUMABLE
                    ),
                  }}
                />
              </Tooltip>
            </IconButton>
            <IconButton
              sx={{
                height: isMobile ? "40px" : "30px",
                width: isMobile ? "40px" : "30px",
              }}
              onClick={() => {
                handleDeleteRow(params.row.id);
              }}
            >
              <Tooltip title="Delete this row forever">
                <DeleteForeverIcon sx={{ color: "#f44030" }} />
              </Tooltip>
            </IconButton>
          </>
        );
      },
    },
    ...(isMobile
      ? []
      : [
          {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            headerClassName: "lastcolumnSeparator",
            headerAlign: "right",
            renderCell: (params) => {
              if (UNEDITABLE_ROW_TYPES.includes(params.row.type)) {
                return <></>;
              }
              return (
                <CustomDetailPanelToggle id={params.id} value={params.value} />
              );
            },
          },
        ]),
  ];

  useEffect(() => {
    if (isAddItemOpen) {
      setAddRow(tempAddRow);
    } else {
      setAddRow(baseAddRow);
    }
  }, [isAddItemOpen]);

  const getDetailPanelHeight = React.useCallback(() => 500, []);

  const handleCellModesModelChange = React.useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  return (
    <div
      ref={(node) => {
        drop(node);
        gridRef.current = node;
      }}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      style={{ opacity: isOver ? 0.7 : 1 }}
    >
      <DataGridPro
        sx={{
          borderRadius: 0,
          pr: 0,
          fontSize: isMobile ? "20px" : "14px",
        }}
        autoHeight
        rows={Object.values(tableRows)}
        pinnedRows={pinnedRows}
        columns={columns}
        disableSelectionOnClick
        hideFooter
        experimentalFeatures={{ newEditingApi: true, rowPinning: true }}
        isCellEditable={(params) =>
          !UNEDITABLE_ROW_TYPES.includes(params.row.type) ||
          params.colDef.field === "unit"
        }
        getRowHeight={({ id, densityFactor }) => {
          if (id === "ADDING_ROW") {
            return isMobile ? 360 * densityFactor : 700 * densityFactor;
          }
          if (id === "ADD_ROW") {
            return 0;
          }
          if (id === "SUMMARY") {
            return 40 * densityFactor;
          }
          return 46 * densityFactor;
        }}
        components={{
          NoRowsOverlay: () => (
            <Box
              sx={{
                textAlign: "center",
                verticalAlign: "center",
                lineHeight: isMobile ? "144px" : "72px",
                color: "#999",
              }}
            >
              No Items
            </Box>
          ),
          Row: (rowProps) => (
            <DraggableGridRow {...rowProps} tableIndex={tableIndex} />
          ),
        }}
        density={isMobile ? "comfortable" : "compact"}
        processRowUpdate={handleRowUpdate}
        onProcessRowUpdateError={handleRowEditError}
        rowReordering={!isMobile}
        disableColumnReorder
        disableColumnPinning
        onRowOrderChange={handleRowOrderChange}
        getDetailPanelHeight={getDetailPanelHeight}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        headerHeight={36}
        getDetailPanelContent={(props) => (
          <DetailPanelContent
            {...props}
            tableIndex={tableIndex}
            handleSetTableData={handleSetTableData}
            tableRows={tableRows}
            tableId={tableId}
            affiliateLinksAreOff={affiliateLinksAreOff}
            darkMode={darkMode}
          />
        )}
        disableColumnMenu
      />
    </div>
  );
};

const areEqual = (prevProps, nextProps) => _.isEqual(prevProps, nextProps);

export default React.memo(DataGridContainer, areEqual);
