import { Markup } from "interweave";
import ArticleGearModule from "../Articles/ArticleGearModule";
import MODULE_KEYS from "../Articles/constants/moduleKeys.json";
import ArticleFilteredGearModule from "../AdminTools/ArticleFilteredGearModule";
import { Stack } from "@mui/material";

const buildCustomHTML = (customData, introData, isDarkMode) => {
  const finalData = [];
  customData.forEach(({ type, children }) => {
    switch (type) {
      case MODULE_KEYS.RAW_HTML:
        children.forEach(({ format, value }) => {
          const sanitizedData = value.replace(/\\./g, ".");
          finalData.push(
            <Markup content={value} key={JSON.stringify(sanitizedData)} />
          );
        });
        break;
      case MODULE_KEYS.GEAR_CARD:
        const gearCardProps = {};
        children.forEach((child) => {
          const { key, value } = child;
          gearCardProps[key] = value;
        });
        finalData.push(
          <ArticleGearModule
            key={JSON.stringify(gearCardProps)}
            gear={gearCardProps}
            isDarkMode={isDarkMode}
            optional={{ source: introData.source }}
          />
        );
        break;
      case MODULE_KEYS.FILTERED_GEAR:
        finalData.push(
          <ArticleFilteredGearModule
            key={JSON.stringify(children)}
            data={children}
            isDarkMode={isDarkMode}
          />
        );
        break;
    }
  });
  return <Stack gap={1}>{finalData}</Stack>;
};

export const buildArticleData = (article) => {
  const { seo, introData, data } = article;
  return {
    seo,
    getArticleHtml: (isDarkMode) =>
      buildCustomHTML(data, introData, isDarkMode),
    ...introData,
  };
};
