import React from "react";
import Papa from "papaparse";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Tooltip,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import itemAddColumns from "./definitions/itemAddColumns";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { bulkAddItemToGearExplorePage } from "../../utils";

const AdminBulkAddModule = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [selectedItemTypeToAdd, setSelectedItemTypeToAdd] =
    React.useState("sleepingBags");
  const [hasError, setHasError] = React.useState(false);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [saved, setSaved] = React.useState(false);

  const handleClose = () => {
    setSaved(false);
    setHasError(false);
    setData([]);
    setIsOpen(false);
  };

  const handleSelectedItemTypeToAdd = (e) => {
    setSelectedItemTypeToAdd(e.target.value);
  };

  const handleFileChange = (e) => {
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        setData(results.data);
        let rowHasError = false;
        results.data.forEach((row) => {
          const columns = itemAddColumns[selectedItemTypeToAdd];
          columns.forEach((column) => {
            if (
              row[column.key] &&
              column.mustBeNumber &&
              isNaN(row[column.key])
            ) {
              rowHasError = true;
            }
          });
        });
        setSaved(false);
        setHasError(rowHasError);
      },
    });
  };

  const handleBulkAdd = async () => {
    setIsSaving(true);
    const shouldAddToAutocompleteSet = new Set(rowSelectionModel);
    const itemsToCreate = data.map((data, index) => ({
      ...data,
      shouldAddToAutocomplete: shouldAddToAutocompleteSet.has(index),
    }));
    await bulkAddItemToGearExplorePage(itemsToCreate, selectedItemTypeToAdd);
    setIsSaving(false);
    setSaved(true);
  };

  const buildBulkItemTable = () => {
    const columns = itemAddColumns[selectedItemTypeToAdd];
    const createdColumns = columns.map(({ label, key, mustBeNumber }) => ({
      field: key,
      headerName: label,
      resizable: true,
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={
            params.value && mustBeNumber && isNaN(params.value)
              ? "Must be a number"
              : params.value
          }
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              border:
                params.value && mustBeNumber && isNaN(params.value)
                  ? "1px solid red"
                  : "none",
            }}
          >
            {params.value}
          </Box>
        </Tooltip>
      ),
    }));

    const rows = data.map((data, index) => ({
      ...data,
      id: index,
    }));

    return (
      <Box sx={{ height: "600px", padding: "0px 10px 0px 10px" }}>
        <DataGridPro
          rows={rows}
          columns={createdColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          density="compact"
          onSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          selectionModel={rowSelectionModel}
        />
      </Box>
    );
  };

  return (
    <>
      <Dialog onClose={handleClose} open={isOpen}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            width: data.length === 0 ? "500px" : "calc(100vw - 100px)",
            padding: "10px 10px 10px 10px",
          }}
        >
          <DialogTitle>{"Bulk Add Items (.tsv format)"}</DialogTitle>
          <IconButton
            onClick={handleClose}
            sx={{ height: "40px", width: "40px" }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack sx={{ padding: "10px 10px 10px 10px" }}>
          <Link
            href="https://docs.google.com/spreadsheets/d/1hNTufPa-BK3MxDOIeJuvNtDUtuhK-Essh8hn7cQHEoc/edit?usp=sharing"
            target="_blank"
          >
            Click to access TSV templates
          </Link>
        </Stack>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel size="small">Item Type to Add</InputLabel>
          <Select
            size="small"
            value={selectedItemTypeToAdd}
            onChange={handleSelectedItemTypeToAdd}
            input={<OutlinedInput label="Item Type to Add" />}
          >
            <MenuItem key="sleeping_bag_select" value="sleepingBags">
              Sleeping Bags & Quilts
            </MenuItem>
            <MenuItem key="tent_select" value="tents">
              Tents
            </MenuItem>
            <MenuItem key="backpack_select" value="packs">
              Backpacks
            </MenuItem>
            <MenuItem key="sleeping_pad_select" value="sleepingPads">
              Sleeping Pads
            </MenuItem>
            <MenuItem key="insulated_jackets_select" value="insulatedJackets">
              Insulated Jackets
            </MenuItem>
            <MenuItem key="autocomplete_select" value="autocomplete">
              Autocomplete
            </MenuItem>
          </Select>
        </FormControl>
        <DialogContent sx={{ height: "auto", width: "auto" }}>
          <input
            type="file"
            name="file"
            accept=".tsv"
            onChange={handleFileChange}
            style={{ display: "block" }}
          />
        </DialogContent>
        {data.length > 0 ? buildBulkItemTable() : <></>}
        <Stack alignItems="center" sx={{ padding: "5px 5px 5px 5px" }}>
          {hasError ? (
            <Tooltip title="Data has Errors">
              <Button
                sx={{ width: "300px" }}
                variant="contained"
                disabled={hasError}
                color="error"
              >
                Data has Errors
              </Button>
            </Tooltip>
          ) : isSaving ? (
            <Button
              variant="outlined"
              color="success"
              sx={{ height: "30px" }}
              disabled
            >
              Adding...
            </Button>
          ) : !saved ? (
            <Button
              color="success"
              sx={{ width: "300px" }}
              variant="contained"
              disabled={data.length <= 0}
              onClick={handleBulkAdd}
            >
              Add Items
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="success"
              sx={{ height: "30px" }}
              disabled
            >
              Saved
            </Button>
          )}
        </Stack>
      </Dialog>
      {isSaving ? (
        <Button
          variant="outlined"
          color="success"
          sx={{ height: "30px" }}
          startIcon={<AddCircleIcon />}
          disabled
        >
          Adding...
        </Button>
      ) : (
        <Button
          variant="outlined"
          sx={{ height: "30px" }}
          startIcon={<AddCircleIcon />}
          color="warning"
          onClick={() => setIsOpen(!isOpen)}
        >
          Bulk Add Items
        </Button>
      )}
    </>
  );
};

export default AdminBulkAddModule;
