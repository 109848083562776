import { Box, Grid, Link, Paper, Stack, Typography } from "@mui/material";

const GearExploreList = ({ justifyContent, showDeals }) => {
  return (
    <Stack>
      <Grid
        container
        spacing={2}
        justifyContent={justifyContent}
        sx={{ maxWidth: "1200px" }}
      >
        <Grid item>
          <Link href={"/gear/tents"} sx={{ textDecoration: "none" }}>
            <Paper
              sx={{
                height: "200px",
                width: "250px",
                "&:hover": { opacity: "0.6", cursor: "pointer" },
              }}
            >
              <Stack>
                <Box
                  sx={{
                    width: "250px",
                    height: "150px",
                    position: "relative",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "230px",
                      maxHeight: "130px",
                      position: "absolute",
                      margin: "auto",
                      top: "0",
                      bottom: "0",
                      left: "0",
                      right: "0",
                    }}
                    src="/xmid2.png"
                    alt="Tents"
                  ></img>
                </Box>
                <Typography sx={{ textAlign: "center" }}>Tents</Typography>
              </Stack>
            </Paper>
          </Link>
        </Grid>
        <Grid item>
          <Link href={"/gear/sleeping-pads"} sx={{ textDecoration: "none" }}>
            <Paper
              sx={{
                height: "200px",
                width: "250px",
                "&:hover": { opacity: "0.6", cursor: "pointer" },
              }}
            >
              <Stack>
                <Box
                  sx={{
                    width: "250px",
                    height: "150px",
                    position: "relative",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "230px",
                      maxHeight: "130px",
                      position: "absolute",
                      margin: "auto",
                      top: "0",
                      bottom: "0",
                      left: "0",
                      right: "0",
                    }}
                    src="/helix.png"
                    alt="Sleeping Pads"
                  ></img>
                </Box>
                <Typography sx={{ textAlign: "center" }}>
                  Sleeping Pads
                </Typography>
              </Stack>
            </Paper>
          </Link>
        </Grid>
        <Grid item>
          <Link href="/gear/backpacks" sx={{ textDecoration: "none" }}>
            <Paper
              sx={{
                height: "200px",
                width: "250px",
                "&:hover": { opacity: "0.6", cursor: "pointer" },
              }}
            >
              <Stack>
                <Box
                  sx={{
                    width: "250px",
                    height: "150px",
                    position: "relative",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "230px",
                      maxHeight: "130px",
                      position: "absolute",
                      margin: "auto",
                      top: "0",
                      bottom: "0",
                      left: "0",
                      right: "0",
                    }}
                    src="/reiflash.png"
                    alt="Packs"
                  ></img>
                </Box>
                <Typography sx={{ textAlign: "center" }}>Backpacks</Typography>
              </Stack>
            </Paper>
          </Link>
        </Grid>
        <Grid item>
          <Link href="/gear/sleeping-bags" sx={{ textDecoration: "none" }}>
            <Paper
              sx={{
                height: "200px",
                width: "250px",
                "&:hover": { opacity: "0.6", cursor: "pointer" },
              }}
            >
              <Stack>
                <Box
                  sx={{
                    width: "250px",
                    height: "150px",
                    position: "relative",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "230px",
                      maxHeight: "130px",
                      position: "absolute",
                      margin: "auto",
                      top: "0",
                      bottom: "0",
                      left: "0",
                      right: "0",
                    }}
                    src="/ee_down.png"
                    alt="Sleeping Bags and Quilts"
                  ></img>
                </Box>
                <Typography sx={{ textAlign: "center" }}>
                  Sleeping Bags and Quilts
                </Typography>
              </Stack>
            </Paper>
          </Link>
        </Grid>
        <Grid item>
          <Link href="/gear/insulated-jackets" sx={{ textDecoration: "none" }}>
            <Paper
              sx={{
                height: "200px",
                width: "250px",
                "&:hover": { opacity: "0.6", cursor: "pointer" },
              }}
            >
              <Stack>
                <Box
                  sx={{
                    width: "250px",
                    height: "150px",
                    position: "relative",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "230px",
                      maxHeight: "130px",
                      position: "absolute",
                      margin: "auto",
                      top: "0",
                      bottom: "0",
                      left: "0",
                      right: "0",
                    }}
                    src="/cumulus.png"
                    alt="Insulated Jackets"
                  ></img>
                </Box>
                <Typography sx={{ textAlign: "center" }}>
                  Insulated Jackets
                </Typography>
              </Stack>
            </Paper>
          </Link>
        </Grid>
        <Grid item>
          <Link href="/gear/stoves" sx={{ textDecoration: "none" }}>
            <Paper
              sx={{
                height: "200px",
                width: "250px",
                "&:hover": { opacity: "0.6", cursor: "pointer" },
              }}
            >
              <Stack>
                <Box
                  sx={{
                    width: "250px",
                    height: "150px",
                    position: "relative",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "230px",
                      maxHeight: "130px",
                      position: "absolute",
                      margin: "auto",
                      top: "0",
                      bottom: "0",
                      left: "0",
                      right: "0",
                    }}
                    src="/pocketrocket.png"
                    alt="Stoves"
                  ></img>
                </Box>
                <Typography sx={{ textAlign: "center" }}>Stoves</Typography>
              </Stack>
            </Paper>
          </Link>
        </Grid>
        {showDeals ? (
          <Grid item>
            <Link href="/gear" sx={{ textDecoration: "none" }}>
              <Paper
                sx={{
                  height: "200px",
                  width: "250px",
                  "&:hover": { opacity: "0.6", cursor: "pointer" },
                }}
              >
                <Stack alignItems="center">
                  <Stack>
                    <Stack direction="row" sx={{ opacity: "0.5" }}>
                      <Box
                        sx={{
                          width: "80px",
                          height: "75px",
                          position: "relative",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "80px",
                            maxHeight: "50px",
                            position: "absolute",
                            margin: "auto",
                            top: "0",
                            bottom: "0",
                            left: "0",
                            right: "0",
                          }}
                          src="/xmid2.png"
                          alt="Tents"
                        ></img>
                      </Box>
                      <Box
                        sx={{
                          width: "80px",
                          height: "75px",
                          position: "relative",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "80px",
                            maxHeight: "50px",
                            position: "absolute",
                            margin: "auto",
                            top: "0",
                            bottom: "0",
                            left: "0",
                            right: "0",
                          }}
                          src="/reiflash.png"
                          alt="Backpacks"
                        ></img>
                      </Box>
                    </Stack>
                    <Stack direction="row" sx={{ opacity: "0.5" }}>
                      <Box
                        sx={{
                          width: "80px",
                          height: "75px",
                          position: "relative",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "80px",
                            maxHeight: "50px",
                            position: "absolute",
                            margin: "auto",
                            top: "0",
                            bottom: "0",
                            left: "0",
                            right: "0",
                          }}
                          src="/ee_down.png"
                          alt="Sleeping Bags & Quilts"
                        ></img>
                      </Box>
                      <Box
                        sx={{
                          width: "80px",
                          height: "75px",
                          position: "relative",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "80px",
                            maxHeight: "50px",
                            position: "absolute",
                            margin: "auto",
                            top: "0",
                            bottom: "0",
                            left: "0",
                            right: "0",
                          }}
                          src="/cumulus.png"
                          alt="Insulated Jackets"
                        ></img>
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        width: "250px",
                        height: "150px",
                        position: "absolute",
                      }}
                    >
                      <img
                        style={{
                          maxWidth: "230px",
                          maxHeight: "130px",
                          position: "absolute",
                          margin: "auto",
                          top: "0",
                          bottom: "0",
                          left: "-45%",
                          right: "0",
                        }}
                        src="https://storage.googleapis.com/pack_wizard/gear/generic/deal.png"
                        alt="Insulated Jackets"
                      ></img>
                    </Box>
                  </Stack>
                  <Typography sx={{ textAlign: "center" }}>
                    Daily Deals
                  </Typography>
                </Stack>
              </Paper>
            </Link>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </Stack>
  );
};

export default GearExploreList;
