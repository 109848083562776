import initialTableStructure from "../constants/initialTableStructure";
import AUTHENTICATED_ENDPOINT_KEYS from "../constants/authenticatedEndpointKeys.json";
import UNAUTHENTICATED_ENDPOINT_KEYS from "../constants/unauthenticatedEndpointKeys.json";
import { getAuthenticatedRequest, getUnauthenticatedRequest } from "./requests";
import { logUserInteraction } from "../api/logging";

export const getLastUpdatedAtText = (timeSince) => {
  const plural = "s ago";
  const singular = " ago";

  // Convert to seconds
  const timeSinceInSeconds = timeSince / 1000;

  // Less than a minute
  if (timeSinceInSeconds < 60) {
    const seconds = Math.floor(timeSinceInSeconds);
    return `${seconds} second${seconds === 1 ? singular : plural}`;
  }

  // Less than an hour
  const timeSinceInMinutes = timeSinceInSeconds / 60;
  if (timeSinceInMinutes < 60) {
    const minutes = Math.floor(timeSinceInMinutes);
    return `${minutes} minute${minutes === 1 ? singular : plural}`;
  }

  // Less than a day
  const timeSinceInHours = timeSinceInMinutes / 60; // Changed from /24 to /60
  if (timeSinceInHours < 24) {
    const hours = Math.floor(timeSinceInHours);
    return `${hours} hour${hours === 1 ? singular : plural}`;
  }

  // Less than a month
  const timeSinceInDays = timeSinceInHours / 24; // Now correctly calculating days
  if (timeSinceInDays < 30) {
    const days = Math.round(timeSinceInDays);
    return `${days} day${days === 1 ? singular : plural}`;
  }

  // Months
  const timeSinceInMonths = timeSinceInDays / 30;
  const months = Math.floor(timeSinceInMonths);
  return `${months} month${months === 1 ? singular : plural}`;
};

export const getWeightInGrams = (weight, unit) => {
  if (unit === "g") return Math.floor(Number(weight));
  if (unit === "oz") return Math.floor(Number(weight * 28.34952));
  if (unit === "lb") return Math.floor(Number(weight * 453.592));
  if (unit === "kg") return Math.floor(Number(weight * 1000));
};

export const getWeightInUnitFromGrams = (weightInGrams, newUnit) => {
  if (newUnit === "g") return weightInGrams;
  if (newUnit === "oz") return weightInGrams / 28.34952;
  if (newUnit === "lb") return weightInGrams / 453.592;
  if (newUnit === "kg") return weightInGrams / 1000;
};

export const getFormattedWeightInUnit = (weightInGrams, metric) => {
  if (!weightInGrams) return null;
  if (metric) {
    return weightInGrams > 2000
      ? `${(weightInGrams / 1000).toFixed(2)}kg`
      : `${weightInGrams}g`;
  } else {
    const weightInLbs = Math.floor(weightInGrams / 453.592);
    const ounces = ((weightInGrams % 453.592) / 28.34952).toFixed(1);
    if (weightInLbs > 0) {
      return `${weightInLbs}lb${weightInLbs === 1 ? "" : "s"} ${ounces}oz`;
    } else {
      return `${ounces}oz`;
    }
  }
};

export const convertWeightInUnitToNewUnit = (weight, unit, newUnit) => {
  const weightInGrams = getWeightInGrams(weight, unit);
  return getWeightInUnitFromGrams(weightInGrams, newUnit);
};

export const getWeightPercentage = (weight, totalWeight, fixedLength = 2) => {
  if (!totalWeight || !weight) return `${Number(0).toFixed(fixedLength)}%`;
  return `${Number((weight / totalWeight) * 100).toFixed(fixedLength)}%`;
};

export const logoutUser = () => {
  localStorage.setItem("profile", null);
};

export const getLocalUser = () => {
  return JSON.parse(localStorage.getItem("profile"));
};

export const getLocalUsername = () => {
  if (getUserIsLoggedIn()) {
    const profile = JSON.parse(localStorage.getItem("profile"));
    return profile.username;
  } else {
    return null;
  }
};

export const getUserIsLoggedIn = () =>
  Boolean(JSON.parse(localStorage.getItem("profile")));

export const updateUsernameInLocalSession = async (newUsername) => {
  const profile = JSON.parse(localStorage.getItem("profile"));
  profile.username = newUsername;
  await localStorage.setItem("profile", JSON.stringify(profile));
};

export const getUserLocalTable = () =>
  JSON.parse(localStorage.getItem("table"));

const setUsersNewLocalTable = async () => {
  const localTableData = {
    tableData: [...initialTableStructure],
    packName: "My Pack",
    packDescription: "",
    packCurrency: "USD",
  };
  await localStorage.setItem("table", JSON.stringify(localTableData));
  return localTableData;
};

const convertRowsToMetric = (rows) => {
  const newRows = { ...rows };
  Object.keys(newRows).forEach((rowKey) => {
    const { unit, weight } = newRows[rowKey];
    const newWeight = getWeightInGrams(weight, unit);
    newRows[rowKey] = {
      ...newRows[rowKey],
      weight: newWeight >= 2000 ? newWeight / 1000 : newWeight,
      unit: newWeight >= 2000 ? "kg" : "g",
    };
  });
  return newRows;
};

const convertPackDataToMetric = (packData) => {
  const newPackData = packData.map((packTable) => ({
    ...packTable,
    rows: convertRowsToMetric(packTable.rows),
    summaryRowUnit: "kg",
  }));
  return newPackData;
};

const convertLocalPackToMetric = async () => {
  const userTable = getUserLocalTable();
  const { tableData } = userTable;
  const newPackData = convertPackDataToMetric(tableData);
  const newLocalTableData = {
    ...userTable,
    tableData: newPackData,
  };
  await localStorage.setItem("table", JSON.stringify(newLocalTableData));
  return { success: true };
};

const convertRowsToImperial = (rows) => {
  const newRows = { ...rows };
  Object.keys(newRows).forEach((rowKey) => {
    const { unit, weight } = newRows[rowKey];
    newRows[rowKey] = {
      ...newRows[rowKey],
      weight: Number(
        convertWeightInUnitToNewUnit(weight, unit, "oz").toFixed(2)
      ),
      unit: "oz",
    };
  });
  return newRows;
};

const convertPackDataToImperial = (packData) => {
  const newPackData = packData.map((packTable) => ({
    ...packTable,
    rows: convertRowsToImperial(packTable.rows),
    summaryRowUnit: "oz",
  }));
  return newPackData;
};

const convertLocalPackToImperial = async () => {
  const userTable = getUserLocalTable();
  const { tableData } = userTable;
  const newPackData = convertPackDataToImperial(tableData);
  const newLocalTableData = {
    ...userTable,
    tableData: newPackData,
  };
  await localStorage.setItem("table", JSON.stringify(newLocalTableData));
  return { success: true };
};

export const setUserLocalTable = async (
  packName,
  tableData,
  packDescription
) => {
  const localTableData = {
    tableData,
    packName,
    packDescription,
  };
  await localStorage.setItem("table", JSON.stringify(localTableData));
};

export const updatePack = async (packId, packData) => {
  const userIsLoggedIn = getUserIsLoggedIn();
  // user is logged in
  if (userIsLoggedIn) {
    await getAuthenticatedRequest(AUTHENTICATED_ENDPOINT_KEYS.UPDATE_PACK, {
      packId,
      packData,
    });
  } else {
    const usersLocalTable = getUserLocalTable();
    if (usersLocalTable) {
      await localStorage.setItem(
        "table",
        JSON.stringify({
          tableData: packData.data ? packData.data : usersLocalTable.tableData,
          packName: packData.packName
            ? packData.packName
            : usersLocalTable.packName,
          packDescription: packData.description
            ? packData.description
            : usersLocalTable.description,
        })
      );
    }
  }
};

export const getUsersDefaultPack = async () => {
  const userIsLoggedIn = getUserIsLoggedIn();
  logUserInteraction("session", "pack");
  // user is logged in
  if (userIsLoggedIn) {
    const table = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.GET_DEFAULT_TABLE
    );
    const {
      tableData,
      tableId,
      packName,
      packDescription,
      packUnit,
      tags,
      isPublic,
      analytics,
      packCurrency,
      hasAffiliateLinksTurnedOff,
    } = table;
    return {
      userTable: tableData,
      tableId,
      packName,
      packDescription,
      packUnit,
      tags,
      isPublic,
      packCurrency,
      analytics,
      hasAffiliateLinksTurnedOff,
    };
  } else {
    const usersLocalTable = getUserLocalTable();
    if (usersLocalTable) {
      return {
        tableId: null,
        userTable: usersLocalTable.tableData,
        packName: usersLocalTable.packName,
        packDescription: usersLocalTable.packDescription,
        tags: [],
        isPublic: false,
        analytics: null,
        packCurrency: usersLocalTable.packCurrency || "USD",
        hasAffiliateLinksTurnedOff: false,
      };
    } else {
      const newPackData = await setUsersNewLocalTable();
      return {
        tableId: null,
        userTable: newPackData.tableData,
        packName: newPackData.packName,
        packDescription: newPackData.packDescription,
        isPublic: false,
        tags: [],
        analytics: null,
        packCurrency: newPackData.packCurrency,
        hasAffiliateLinksTurnedOff: false,
      };
    }
  }
};

export const getUsersPackList = async () => {
  const userIsLoggedIn = getUserIsLoggedIn();
  // user is logged in
  if (userIsLoggedIn) {
    const packList = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.GET_PACK_LIST
    );
    return packList;
  } else {
    return [];
  }
};

export const createNewPack = async () => {
  const userIsLoggedIn = getUserIsLoggedIn();
  // user is logged in
  if (userIsLoggedIn) {
    const packList = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.CREATE_NEW_PACK
    );
    return packList;
  } else {
    return null;
  }
};

export const getPackWithId = async (id) => {
  const userIsLoggedIn = getUserIsLoggedIn();
  if (userIsLoggedIn) {
    const selectedPack = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.GET_PACK_WITH_ID,
      { packId: id }
    );
    return selectedPack;
  } else {
    return null;
  }
};

export const deletePackWithId = async (id) => {
  const userIsLoggedIn = getUserIsLoggedIn();
  if (userIsLoggedIn) {
    const deletedPack = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.DELETE_PACK_WITH_ID,
      { packId: id }
    );
    return deletedPack;
  } else {
    return null;
  }
};

export const setUserDefaultPack = async (id) => {
  const userIsLoggedIn = getUserIsLoggedIn();
  if (userIsLoggedIn) {
    const newDefaultPack = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.SET_USER_DEFAULT_PACK,
      { packId: id }
    );
    return newDefaultPack;
  } else {
    return null;
  }
};

export const importFromLighterPack = async (
  link,
  lpItemColumn,
  lpDescriptionColumn,
  importToGC
) => {
  const userIsLoggedIn = getUserIsLoggedIn();
  const settings = {
    itemColumn: lpItemColumn,
    descriptionColumn: lpDescriptionColumn,
    shouldImportToGearCloset: importToGC,
  };
  if (userIsLoggedIn) {
    const newPack = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.IMPORT_FROM_LIGHTERPACK,
      { url: link, settings }
    );
    return newPack;
  } else {
    const newPack = await getUnauthenticatedRequest(
      UNAUTHENTICATED_ENDPOINT_KEYS.IMPORT_FROM_LIGHTERPACK_WHILE_SIGNED_OUT,
      { url: link, settings }
    );
    return newPack;
  }
};

export const generateShareableLink = async (packId) => {
  const userIsLoggedIn = getUserIsLoggedIn();
  if (userIsLoggedIn) {
    const shareableLink = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.GENERATE_SHAREABLE_LINK,
      { packId }
    );
    return shareableLink;
  } else {
    return {};
  }
};

export const getItemsForAutocomplete = async ({ inputValue }) => {
  const response = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.SEARCH_ITEMS_WITH_SUBSTRING,
    { inputValue }
  );
  return response;
};

export const getSharedPackWithId = async (id) => {
  const response = await getAuthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.GET_SHARED_PACK_WITH_ID,
    { id }
  );
  return response;
};

export const addItemToGearCloset = async (item) => {
  const userIsLoggedIn = getUserIsLoggedIn();
  if (userIsLoggedIn) {
    const gearCLosetItem = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.ADD_ITEM_TO_GEAR_CLOSET,
      { item }
    );
    return gearCLosetItem;
  } else {
    // do nothing
  }
};

export const getGearCloset = async () => {
  const userIsLoggedIn = getUserIsLoggedIn();
  if (userIsLoggedIn) {
    const gearCloset = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.GET_GEAR_CLOSET
    );
    return gearCloset;
  } else {
    return [];
  }
};

export const updateGearClosetItem = async (item) => {
  const userIsLoggedIn = getUserIsLoggedIn();
  if (userIsLoggedIn) {
    const gearCloset = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.UPDATE_GEAR_CLOSET_ITEM,
      { item }
    );
    return gearCloset;
  } else {
    return null;
  }
};

export const deleteItemFromGearCloset = async (id) => {
  const userIsLoggedIn = getUserIsLoggedIn();
  if (userIsLoggedIn) {
    const deletedItem = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.DELETE_GEAR_CLOSET_ITEM,
      { id }
    );
    return deletedItem;
  }
  return null;
};

export const getPackSuggestions = async (items) => {
  const suggestedItems = await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.GET_ITEM_SUGGESTIONS,
    { items }
  );
  return suggestedItems;
};

export const duplicatePackWithId = async (id) => {
  const userIsLoggedIn = getUserIsLoggedIn();
  if (userIsLoggedIn) {
    const duplicatedPack = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.DUPLICATE_PACK_WITH_ID,
      { id }
    );
    return duplicatedPack;
  }
  return null;
};

export const submitMessage = async (contactForm) => {
  const userIsLoggedIn = getUserIsLoggedIn();
  if (userIsLoggedIn) {
    const submittedMessage = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.SUBMIT_CONTACT_US_MESSAGE,
      { contactForm }
    );
    return submittedMessage;
  }
  const submittedMessage = await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.SUBMIT_CONTACT_US_MESSAGE_LOGGED_OUT,
    { contactForm }
  );
  return submittedMessage;
};

export const updatePackToMetric = async (tableId) => {
  const userIsLoggedIn = getUserIsLoggedIn();
  if (userIsLoggedIn) {
    const packWasUpdated = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.UPDATE_PACK_TO_METRIC,
      { packId: tableId }
    );
    return packWasUpdated;
  } else {
    return await convertLocalPackToMetric();
  }
};

export const updatePackToImperial = async (tableId) => {
  const userIsLoggedIn = getUserIsLoggedIn();
  if (userIsLoggedIn) {
    const packWasUpdated = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.UPDATE_PACK_TO_IMPERIAL,
      { packId: tableId }
    );
    return packWasUpdated;
  } else {
    return await convertLocalPackToImperial();
  }
};

export const addItemToPackFromGearExplorer = async (
  packId,
  categoryIndex,
  itemId,
  gearType
) => {
  const userIsLoggedIn = getUserIsLoggedIn();
  if (userIsLoggedIn) {
    getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.ADD_ITEM_TO_PACK_FROM_GEAR_EXPLORER,
      { packId, categoryIndex, itemId, gearType }
    );
  }
};

export const logErrorOnServer = async (error) => {
  await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.LOG_ERROR_ON_SERVER,
    { error }
  );
};

export const handleUpdatePackUnit = async (packId, unit) => {
  if (getUserIsLoggedIn()) {
    await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.UPDATE_PACK_UNIT,
      { packId, unit }
    );
  } else {
    await localStorage.setItem("preferredPackWeight", unit);
  }
};

export const fetchAdminData = async () => {
  if (getUserIsLoggedIn()) {
    const data = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.GET_ADMIN_DATA
    );
    return data;
  } else {
    return null;
  }
};

export const fetchProductsForComparison = async (productIds, type) => {
  const data = await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.GET_PRODUCTS_FOR_COMPARISON,
    { productIds, type }
  );
  return data;
};

export const fetchLinkManagerData = async (category) => {
  if (getUserIsLoggedIn()) {
    const data = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.GET_LINK_MANAGER_DATA,
      { category }
    );
    return data;
  } else {
    return null;
  }
};

export const handleUserPackVote = async (voteType, packId) => {
  if (getUserIsLoggedIn()) {
    const data = await getAuthenticatedRequest(
      AUTHENTICATED_ENDPOINT_KEYS.HANDLE_PACK_VOTE,
      { voteType, packId }
    );
    return data;
  } else {
    return null;
  }
};

export const fetchDataForPackDisplay = async (shareId) => {
  const data = await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.GET_DATA_FOR_PACK_DISPLAY,
    { shareId }
  );
  return data;
};

export const submitNewsletterEmail = async (newsletterEmail) => {
  await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.SUBMIT_NEWSLETTER_EMAIL,
    { newsletterEmail }
  );
};

export const addItemToGearClosetWithId = async (itemId, gearType) => {
  await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.ADD_ITEM_TO_GEAR_CLOSET_WITH_ID,
    { itemId, gearType }
  );
};

export const flagItemForIncorrectData = async (itemId, gearType) => {
  await getUnauthenticatedRequest(UNAUTHENTICATED_ENDPOINT_KEYS.REPORT_ITEM, {
    itemId,
    gearType,
  });
};

export const getUserPackDataForAddToPackDialog = async () => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.GET_USER_PACK_DATA_FOR_ADD_TO_PACK_DIALOG
  );
  return data;
};

export const updatePackSettings = async (
  packId,
  packVisibility,
  packSettings,
  currency
) => {
  await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.UPDATE_PACK_SETTINGS,
    {
      packId,
      packVisibility,
      packSettings,
      currency,
    }
  );
};

export const fetchPublicPacksForPackExplorer = async () => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.FETCH_PUBLIC_PACKS_FOR_PACK_EXPLORER
  );
  return data;
};

export const fetchUserProfile = async (username) => {
  const data = await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.FETCH_USER_PROFILE,
    { username }
  );
  return data;
};

export const validateUsername = async (username, email) => {
  const data = await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.VALIDATE_USERNAME,
    { username, email }
  );
  return data;
};

export const permanentlyUpdateUsername = async (username) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.PERMANENTLY_UPDATE_USERNAME,
    { username }
  );
  return data;
};

export const updateUserPhoto = async (file, type) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.UPDATE_USER_PHOTO,
    { type, file }
  );
  return data;
};

export const updateUserProfile = async (profileSettings) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.UPDATE_USER_PROFILE,
    { profileSettings }
  );
  return data;
};

export const getUsersPackAnalytics = async () => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.GET_USERS_PACK_ANALYTICS
  );
  return data;
};

export const getUsersPublicPacks = async (username) => {
  const data = await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.GET_USERS_PUBLIC_PACKS,
    { username }
  );
  return data;
};

export const updateGearDatabaseItem = async (
  category,
  itemId,
  updateObject
) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.UPDATE_GEAR_DATABASE_ITEM,
    { category, itemId, updateObject }
  );
  return data;
};

export const fetchReportsForAdminPanel = async () => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.GET_REPORTS_FOR_ADMIN_PANEL
  );
  return data;
};

export const setStatusForReportInAdminPanel = async (reportId, newStatus) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.SET_STATUS_FOR_REPORT,
    {
      reportId,
      newStatus,
    }
  );
  return data;
};

export const addItemToAutocomplete = async (itemToCreate) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.CREATE_ITEM_FOR_AUTOCOMPLETE,
    { item: itemToCreate }
  );
  return data;
};

export const addItemToGearExplorePage = async (
  itemToCreate,
  gearType,
  shouldAddToAutocomplete
) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.CREATE_ITEM_FOR_GEAR_EXPLORE,
    { item: itemToCreate, gearType, shouldAddToAutocomplete }
  );
  return data;
};

export const bulkAddItemToGearExplorePage = async (itemsToCreate, gearType) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.BULK_CREATE_ITEM_FOR_GEAR_EXPLORE,
    { items: itemsToCreate, gearType }
  );
  return data;
};

export const updateItemDeleteState = async (itemId, markAsDeleted) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.UPDATE_ITEM_DELETE_STATE,
    { itemId, markAsDeleted }
  );
  return data;
};

export const fetchGearMetrics = async (gearType, productId) => {
  const data = await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.FETCH_GEAR_METRICS,
    { gearType, productId }
  );
  return data;
};

export const getLoggedInUserDetails = async () => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.GET_LOGGED_IN_USER_DETAILS
  );
  return data;
};

export const addReview = async (reviewData) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.CREATE_REVIEW,
    { reviewData }
  );
  return data;
};

export const fetchReviews = async (gearType, itemId) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.GET_REVIEWS,
    { gearType, itemId }
  );
  return data;
};

export const editReview = async (reviewData) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.EDIT_REVIEW,
    { reviewData }
  );
  return data;
};

export const markReviewAsReported = async (reviewId) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.REPORT_REVIEW,
    { reviewId }
  );
  return data;
};

export const markReviewAsHelpful = async (reviewId) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.MARK_REVIEW_AS_HELPFUL,
    { reviewId }
  );
  return data;
};

export const fetchAllReviewsForAdminPanel = async () => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.GET_REVIEW_DATA_FOR_ADMIN_PANEL
  );
  return data;
};

export const getItemsForGTINAudit = async () => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.GET_ITEMS_FOR_GTIN_AUDIT
  );
  return data;
};

export const deleteDuplicatesFromGearCloset = async (duplicateIds) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.DELETE_DUPLICATES_FROM_GEAR_CLOSET,
    { duplicateIds }
  );
  return data;
};

export const updatePricingData = async (itemToUpdate) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.UPDATE_PRICING_VALUES,
    { itemToUpdate }
  );
  return data;
};

export const refreshPricingData = async (itemToUpdate) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.REFRESH_PRICING_DATA,
    { itemToUpdate }
  );
  return data;
};

export const fetchDailyPacksForDisplay = async () => {
  const data = await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.GET_DAILY_PACKS
  );
  return data;
};

export const createNewBin = async (selectedItems) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.CREATE_NEW_BIN,
    { selectedItems }
  );
  return data;
};

export const getBins = async () => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.GET_USER_BINS
  );
  return data;
};

export const updateUserBin = async (binId, binUpdates) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.UPDATE_USER_BIN,
    { binId, binUpdates }
  );
  return data;
};

export const deleteUserBin = async (binId) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.DELETE_USER_BIN,
    { binId }
  );
  return data;
};

export const getDealsForDashboard = async () => {
  const data = await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.GET_DEALS
  );
  return data;
};

export const getPriceHistory = async (itemId, gearType) => {
  const data = await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.GET_PRICE_HISTORY,
    { itemId, gearType }
  );
  return data;
};

export const getDailyDealsByGearType = async (gearType) => {
  const data = await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.GET_DAILY_DEALS_BY_GEAR_TYPE,
    { gearType }
  );
  return data;
};

export const addRetailerToGear = async (itemId, category, retailerToAdd) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.UPDATE_ITEM_RETAILER,
    { itemId, category, retailerToAdd }
  );
  return data;
};

export const importLocalPack = async () => {
  const { tableData, packName } = getUserLocalTable();
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.IMPORT_LOCAL_PACK,
    { packName, data: tableData }
  );
  return data;
};

export const getOptionsForGearTypeAutocomplete = async (
  gearType,
  searchQuery
) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.GET_OPTIONS_FOR_GEAR_TYPE_AUTOCOMPLETE,
    { gearType, searchQuery }
  );
  return data;
};

export const getArticlesforSelection = async () => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.GET_ARTICLES_FOR_SELECTION
  );
  return data;
};

export const createArticle = async () => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.CREATE_ARTICLE
  );
  return data;
};

export const fetchArticleForEditing = async (articleId) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.FETCH_ARTICLE_FOR_EDITING_BY_ID,
    { articleId }
  );
  return data;
};

export const updateArticleEdits = async (articleId, edits) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.UPDATE_ARTICLE_EDITS_BY_ID,
    { articleId, edits }
  );
  return data;
};

export const publishEdits = async (articleId) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.PUBLISH_ARTICLE,
    { articleId }
  );
  return data;
};

export const unpublishArticle = async (articleId) => {
  const data = await getAuthenticatedRequest(
    AUTHENTICATED_ENDPOINT_KEYS.UNPUBLISH_ARTICLE,
    { articleId }
  );
  return data;
};

export const fetchArticlePaths = async () => {
  const data = await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.FETCH_ARTICLE_PATHS
  );
  return data;
};

export const fetchArticleDataForDisplay = async (articleId) => {
  const data = await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.FETCH_ARTICLE_FOR_DISPLAY,
    { articleId }
  );
  return data;
};

export const fetchGearFilterTableData = async (filters) => {
  const data = await getUnauthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.FETCH_FILTERED_DATA_FOR_FILTER_MODULE,
    { filters }
  );
  return data;
};

export const fetchBrandsForGearFilter = async (gearType) => {
  const data = await getAuthenticatedRequest(
    UNAUTHENTICATED_ENDPOINT_KEYS.GET_BRANDS_FOR_GEAR_FILTER,
    { gearType }
  );
  return data;
};
