import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { handleSignUp } from "../auth";
import {
  isValidPassword,
  isValidEmail,
  isValidUsername,
} from "../utils/validationUtils";
import FormHelperText from "@mui/material/FormHelperText";
import { debounce } from "lodash";
import { Stack, Typography } from "@mui/material";

const SignUpForm = ({ setIsLogin, handleSetUserAndClose }) => {
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [invalidPassword, setInvalidPassword] = React.useState(false);
  const [invalidUsername, setInvalidUsername] = React.useState(false);

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    username: "",
    showPassword: false,
  });

  // Refs for focusing
  const emailRef = React.useRef(null);
  const usernameRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const submitButtonRef = React.useRef(null);

  const handleChange = (prop) => (event) => {
    if (prop === "email") {
      setInvalidEmail(false);
    }
    if (prop === "password") {
      setInvalidPassword(false);
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleValidateAndSetUsername = async (username) => {
    if (username.length === 0) {
      setValues({ ...values, username: "" });
      setInvalidUsername(false);
      return;
    }
    const usernameIsValid = await isValidUsername(username, values.email);
    setInvalidUsername(!usernameIsValid);

    setValues({ ...values, username });
  };

  const debouncedUsernameValidator = debounce(
    handleValidateAndSetUsername,
    300
  );

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickSignIn = () => {
    setIsLogin(true);
  };

  const handleSubmit = async () => {
    const { email, password, username } = values;
    const usernameIsValid = await isValidUsername(username, email);
    if (isValidEmail(email)) {
      if (usernameIsValid) {
        if (isValidPassword(password)) {
          const signUpResponse = await handleSignUp(values);
          if (
            signUpResponse.message === "User already exists" ||
            !signUpResponse.token
          ) {
            setInvalidEmail(true);
            emailRef.current?.focus();
          } else {
            handleSetUserAndClose(signUpResponse);
          }
          return;
        } else {
          setInvalidPassword(true);
          passwordRef.current?.focus();
        }
      } else {
        setInvalidUsername(true);
        usernameRef.current?.focus();
      }
    } else {
      setInvalidEmail(true);
      emailRef.current?.focus();
    }
  };

  const handleKeyPress = (e, nextRef) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (nextRef?.current) {
        nextRef.current.focus();
      } else {
        handleSubmit();
      }
    }
  };

  // Focus email input on mount
  React.useEffect(() => {
    emailRef.current?.focus();
  }, []);

  return (
    <>
      <DialogTitle
        style={{
          width: "400px",
          textAlign: "center",
          paddingTop: "30px",
          fontWeight: "bold",
        }}
      >
        SIGN UP
      </DialogTitle>
      <DialogContent
        style={{ width: "400px", height: "470px", paddingTop: "30px" }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <TextField
              inputRef={emailRef}
              error={invalidEmail}
              required
              label="Email"
              defaultValue=""
              style={{ width: "336px" }}
              variant="standard"
              onChange={handleChange("email")}
              onKeyPress={(e) => handleKeyPress(e, usernameRef)}
              inputProps={{
                tabIndex: 1,
                "aria-label": "Email address",
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <TextField
              inputRef={usernameRef}
              error={invalidUsername}
              helperText={
                <Stack>
                  <Typography variant="subtitle2" sx={{ color: "grey" }}>
                    Leave this blank to choose your username later
                  </Typography>
                  {invalidUsername ? (
                    <Typography variant="subtitle2">
                      Username is already taken
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Stack>
              }
              inputProps={{
                maxLength: 20,
                tabIndex: 2,
                "aria-label": "Username",
              }}
              label="Username"
              defaultValue=""
              style={{ width: "336px" }}
              variant="standard"
              onKeyDown={(e) => {
                if (e.key === " ") {
                  e.preventDefault();
                }
                handleKeyPress(e, passwordRef);
              }}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(
                  /[^a-zA-Z0-9]/g,
                  ""
                );
                e.target.value = sanitizedValue;
                debouncedUsernameValidator(sanitizedValue);
              }}
            />
          </FormControl>
          <FormControl
            sx={{ m: 1, width: "25ch", marginTop: "0px" }}
            variant="standard"
          >
            <InputLabel htmlFor="password-input">Password</InputLabel>
            <Input
              id="password-input"
              inputRef={passwordRef}
              required
              error={invalidPassword}
              style={{ width: "336px" }}
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              onKeyPress={(e) => handleKeyPress(e, submitButtonRef)}
              inputProps={{
                tabIndex: 3,
                "aria-label": "Password",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      values.showPassword ? "Hide password" : "Show password"
                    }
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    tabIndex={4}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {invalidPassword ? (
              <FormHelperText
                style={{ width: "330px" }}
                id="component-error-text"
              >
                Passwords must be 8 and 72 characters long and contain at least
                1 uppercase letter, one lowercase letter and a number
              </FormHelperText>
            ) : (
              <></>
            )}
          </FormControl>
          <Button
            ref={submitButtonRef}
            variant="contained"
            style={{ width: "340px", marginLeft: "5px", marginTop: "30px" }}
            onClick={handleSubmit}
            tabIndex={5}
            type="submit"
          >
            SIGN UP
          </Button>
          <Button
            variant="text"
            style={{
              width: "336px",
              textAlign: "center",
              marginTop: "76px",
              color: "white",
              textTransform: "none",
              marginLeft: "5px",
            }}
            onClick={handleClickSignIn}
            tabIndex={6}
          >
            Already have an account? Login
          </Button>
        </form>
      </DialogContent>
    </>
  );
};

export default SignUpForm;
