import React from "react";
import {
  Button,
  Chip,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";
import Check from "@mui/icons-material/Check";

const ChipList = ({ gearType, filters, gearMetrics = {} }) => {
  const thisProduct = gearMetrics.thisProduct || {};
  if (gearType === "tent") {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="subtitle1" sx={{ opacity: 0.8 }}>
          Comparing:{" "}
        </Typography>
        <Chip
          size="small"
          label={
            !filters.capacity
              ? "All Capacities"
              : `${thisProduct.capacity} person only`
          }
        />
        <Chip
          size="small"
          label={!filters.setup ? "All Setups" : `${thisProduct.setup} only`}
        />
      </Stack>
    );
  } else if (gearType === "sleepingPad") {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="subtitle1" sx={{ opacity: 0.8 }}>
          Comparing:{" "}
        </Typography>
        <Chip
          size="small"
          label={!filters.rValue ? "All R-Values" : `Similar R-Values only`}
        />
        <Chip
          size="small"
          label={!filters.length ? "All Lengths" : `Similar Lengths only`}
        />
        <Chip
          size="small"
          label={!filters.width ? "All Widths" : `Similar Widths only`}
        />
        <Chip
          size="small"
          label={!filters.type ? "All Types" : `${thisProduct.type} only`}
        />
      </Stack>
    );
  } else if (gearType === "backpack") {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="subtitle1" sx={{ opacity: 0.8 }}>
          Comparing:{" "}
        </Typography>{" "}
        <Chip
          size="small"
          label={!filters.volume ? "Any Volume" : `Similar Volume only`}
        />
        <Chip
          size="small"
          label={
            !filters.framed
              ? "All Frame Styles"
              : `${
                  thisProduct.framed.toLowerCase() === "yes"
                    ? "Framed Packs"
                    : "Frameless Packs"
                } only`
          }
        />
      </Stack>
    );
  } else if (gearType === "sleepingBag") {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="subtitle1" sx={{ opacity: 0.8 }}>
          Comparing:{" "}
        </Typography>{" "}
        <Chip
          size="small"
          label={
            !filters.comfortRating
              ? "Any Comfort Rating"
              : `Similar Comfort Rating only`
          }
        />
        <Chip
          size="small"
          label={
            !filters.insulationType
              ? "All Insulation Types"
              : `${
                  thisProduct.insulationType.toLowerCase() === "down"
                    ? "Down"
                    : "Synthetic"
                } only`
          }
        />
        <Chip
          size="small"
          label={!filters.type ? "All Types" : `${thisProduct.type} only`}
        />
        <Chip
          size="small"
          label={!filters.fitsTo ? "All Lengths" : `Similar Lengths only`}
        />
      </Stack>
    );
  }
};

const createMenuItems = (gearType, filters, toggleFilter) => {
  if (gearType === "tent") {
    return (
      <>
        <MenuItem>
          <ListItemText
            selected={!filters.capacity}
            inset={filters.capacity}
            onClick={() => {
              toggleFilter("capacity");
            }}
          >
            {!filters.capacity && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            Compare All Capacities
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemText
            selected={!filters.setup}
            inset={filters.setup}
            onClick={() => {
              toggleFilter("setup");
            }}
          >
            {!filters.setup && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            Compare All Setups
          </ListItemText>
        </MenuItem>
      </>
    );
  } else if (gearType === "sleepingPad") {
    return (
      <>
        <MenuItem>
          <ListItemText
            selected={!filters.rValue}
            inset={filters.rValue}
            onClick={() => {
              toggleFilter("rValue");
            }}
          >
            {!filters.rValue && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            Compare All R-Values
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemText
            selected={!filters.length}
            inset={filters.length}
            onClick={() => {
              toggleFilter("length");
            }}
          >
            {!filters.length && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            Compare All Lengths
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemText
            selected={!filters.width}
            inset={filters.width}
            onClick={() => {
              toggleFilter("width");
            }}
          >
            {!filters.width && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            Compare All Widths
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemText
            selected={!filters.type}
            inset={filters.type}
            onClick={() => {
              toggleFilter("type");
            }}
          >
            {!filters.type && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            Compare All Types
          </ListItemText>
        </MenuItem>
      </>
    );
  } else if (gearType === "backpack") {
    return (
      <>
        <MenuItem>
          <ListItemText
            selected={!filters.volume}
            inset={filters.volume}
            onClick={() => {
              toggleFilter("volume");
            }}
          >
            {!filters.volume && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            Compare All Volumes
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemText
            selected={!filters.framed}
            inset={filters.framed}
            onClick={() => {
              toggleFilter("framed");
            }}
          >
            {!filters.framed && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            Compare All Frame Styles
          </ListItemText>
        </MenuItem>
      </>
    );
  } else if (gearType === "sleepingBag") {
    return (
      <>
        <MenuItem>
          <ListItemText
            selected={!filters.comfortRating}
            inset={filters.comfortRating}
            onClick={() => {
              toggleFilter("comfortRating");
            }}
          >
            {!filters.comfortRating && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            Compare All Comfort Ratings
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemText
            selected={!filters.insulationType}
            inset={filters.insulationType}
            onClick={() => {
              toggleFilter("insulationType");
            }}
          >
            {!filters.insulationType && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            Compare All Insulation Materials
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemText
            selected={!filters.type}
            inset={filters.type}
            onClick={() => {
              toggleFilter("type");
            }}
          >
            {!filters.type && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            Compare All Types
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemText
            selected={!filters.fitsTo}
            inset={filters.fitsTo}
            onClick={() => {
              toggleFilter("fitsTo");
            }}
          >
            {!filters.fitsTo && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            Compare All Lengths
          </ListItemText>
        </MenuItem>
      </>
    );
  }
};

const DistributionWeightGraphFilters = ({
  gearType,
  filters,
  toggleFilter,
  gearMetrics,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="space-between"
      sx={{ maxWidth: "770px", overflowX: "scroll" }}
    >
      <ChipList
        filters={filters}
        gearType={gearType}
        gearMetrics={gearMetrics}
      />
      <Button onClick={handleClick}>Filters</Button>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuList dense>
          {createMenuItems(gearType, filters, toggleFilter)}
        </MenuList>
      </Menu>
    </Stack>
  );
};

export default DistributionWeightGraphFilters;
