/**
 * Sanitizes text for metadata by removing HTML tags and normalizing content
 * @param {string} text - The text to sanitize
 * @param {number} maxLength - Maximum length for the description
 * @returns {string} Sanitized text suitable for metadata
 */
const sanitizeMetaText = (text, maxLength = 160) => {
  if (!text) return "";

  // Create a temporary element to safely parse HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = text;

  // Get text content (this removes all HTML tags)
  let cleanText = tempDiv.textContent || tempDiv.innerText || "";

  // Normalize whitespace and special characters
  cleanText = cleanText
    .replace(/\s+/g, " ") // Replace multiple spaces/newlines with single space
    .replace(/^\s+|\s+$/g, "") // Trim start and end
    .replace(/[""]/g, '"') // Normalize quotes
    .replace(/['']/g, "'") // Normalize apostrophes
    .replace(/[<>]/g, "") // Remove any remaining angle brackets
    .replace(/&/g, "and"); // Replace ampersands with 'and'

  // Truncate if needed
  if (cleanText.length > maxLength) {
    cleanText = cleanText.substring(0, maxLength - 3) + "...";
  }

  return cleanText;
};

/**
 * Generates clean metadata for the pack
 * @param {Object} params Pack parameters
 * @returns {Object} Sanitized metadata
 */
export const generatePackMetadata = ({
  packName,
  packDescription,
  weightInGrams,
  id,
  getFormattedWeightInUnit,
}) => {
  const sanitizedName = sanitizeMetaText(packName, 100);
  const sanitizedDescription = sanitizeMetaText(packDescription, 160);

  const weightFormatted = `${getFormattedWeightInUnit(
    weightInGrams,
    false
  )} / ${getFormattedWeightInUnit(weightInGrams, true)}`;

  // Generate clean keywords from pack name
  const keywords = sanitizedName
    ? sanitizedName
        .split(" ")
        .filter((word) => word.length > 1) // Filter out single characters
        .join(",")
    : "";

  return {
    title: sanitizedName,
    ogTitle: `${sanitizedName} - ${weightFormatted}`,
    ogDescription: sanitizedDescription,
    canonicalUrl: `https://www.packwizard.com/s/${id}`,
    ogImage: `/api/image/packPreview?shareableId=${id}`,
    twitterTitle: `${sanitizedName} - ${weightFormatted}`,
    twitterDescription: sanitizedDescription,
    twitterImage: `/api/image/packPreview?shareableId=${id}`,
    keywords: keywords,
    description: sanitizedDescription
      ? `${sanitizedName} - ${sanitizedDescription}`
      : sanitizedName,
  };
};
