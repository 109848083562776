import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { handleSignIn } from "../auth";
import FormHelperText from "@mui/material/FormHelperText";

const SignInForm = ({
  setIsLogin,
  handleSetUserAndClose,
  setForgotPasswordFormIsOpen,
}) => {
  const [credentialError, setCredentialError] = React.useState(false);
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });

  // Add refs for focus management
  const emailRef = React.useRef(null);
  const passwordRef = React.useRef(null);
  const submitButtonRef = React.useRef(null);

  const handleChange = (prop) => (event) => {
    setCredentialError(false);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickSignUp = () => {
    setIsLogin(false);
  };

  const handleSubmit = async () => {
    const signInResponse = await handleSignIn(values);
    const { email, token } = signInResponse;
    if (email && token) {
      handleSetUserAndClose(signInResponse);
    } else {
      setCredentialError(true);
      emailRef.current?.focus();
    }
  };

  const handleClickForgotPassword = () => {
    setForgotPasswordFormIsOpen(true);
  };

  const handleKeyPress = (e, nextRef) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (nextRef?.current) {
        nextRef.current.focus();
      } else {
        handleSubmit();
      }
    }
  };

  // Focus email input on mount
  React.useEffect(() => {
    emailRef.current?.focus();
  }, []);

  return (
    <>
      <DialogTitle
        style={{
          width: "400px",
          textAlign: "center",
          paddingTop: "30px",
          fontWeight: "bold",
        }}
      >
        LOGIN
      </DialogTitle>
      <DialogContent
        style={{ width: "400px", height: "380px", paddingTop: "30px" }}
      >
        <DialogContentText></DialogContentText>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
            <TextField
              inputRef={emailRef}
              error={credentialError}
              label="Email"
              defaultValue=""
              style={{ width: "336px" }}
              variant="standard"
              onChange={handleChange("email")}
              onKeyPress={(e) => handleKeyPress(e, passwordRef)}
              inputProps={{
                tabIndex: 1,
                "aria-label": "Email address",
              }}
            />
          </FormControl>
          <FormControl
            sx={{ m: 1, width: "25ch", marginTop: "30px" }}
            variant="standard"
          >
            <InputLabel htmlFor="password-input">Password</InputLabel>
            <Input
              id="password-input"
              inputRef={passwordRef}
              error={credentialError}
              style={{ width: "336px" }}
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              onKeyPress={(e) => handleKeyPress(e, submitButtonRef)}
              inputProps={{
                tabIndex: 2,
                "aria-label": "Password",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      values.showPassword ? "Hide password" : "Show password"
                    }
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    tabIndex={3}
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {credentialError ? (
            <FormHelperText
              error
              style={{ marginLeft: "10px", width: "300px" }}
              id="component-error-text"
            >
              Invalid Credentials
            </FormHelperText>
          ) : (
            <></>
          )}
          <Button
            ref={submitButtonRef}
            variant="contained"
            style={{ width: "340px", marginLeft: "5px", marginTop: "30px" }}
            onClick={handleSubmit}
            type="submit"
            tabIndex={4}
          >
            LOGIN
          </Button>
          <Button
            variant="text"
            style={{
              float: "right",
              marginTop: "0px",
              color: "white",
              textTransform: "none",
            }}
            onClick={handleClickForgotPassword}
            tabIndex={5}
          >
            Forgot your password?
          </Button>
          <Button
            variant="text"
            style={{
              width: "336px",
              marginLeft: "5px",
              textAlign: "center",
              marginTop: "40px",
              color: "white",
              textTransform: "none",
            }}
            onClick={handleClickSignUp}
            tabIndex={6}
          >
            Don't have an account? Sign Up
          </Button>
        </form>
      </DialogContent>
    </>
  );
};

export default SignInForm;
