import React from "react";

import { Container } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import GearDealsContainer from "../GearComparison/GearDealsContainer";
import { Helmet } from "react-helmet";
import { fetchArticleDataForDisplay } from "./../../utils";
import { buildArticleData } from "./utils";
import { logUserInteraction } from "../../api/logging";

const DynamicArticleGenerator = ({
  articleId,
  inputArticleData,
  isDarkMode,
}) => {
  const [article, setArticle] = React.useState(null);

  const handleFetchArticleData = async (articleId) => {
    logUserInteraction("session", "article");
    const rawArticleData = await fetchArticleDataForDisplay(articleId);
    const finalizedArticle = buildArticleData(rawArticleData);
    setArticle(finalizedArticle);
  };

  React.useEffect(() => {
    if (articleId) {
      handleFetchArticleData(articleId);
    } else {
      setArticle(inputArticleData);
    }
  }, [articleId]);

  React.useEffect(() => {
    setArticle(inputArticleData);
  }, [inputArticleData]);

  if (!article) return <></>;
  return (
    <Container sx={{ padding: "0px 0px 20px 0px", maxWidth: "1080px" }}>
      <Helmet>
        <title>{article.seo.title}</title>
        <meta property="og:title" content={article.seo.title} />
        <meta property="og:url" content={article.seo.url} />
        <meta property="og:description" content={article.seo.description} />
        <meta property="og:image" content={article.seo.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={article.seo.title} />
        <meta name="twitter:description" content={article.seo.description} />
        <meta name="twitter:image" content={article.seo.image} />
        <meta name="keywords" content={article.seo.keywords} />
        <meta name="description" content={article.seo.description} />
      </Helmet>

      <Stack gap={2}>
        {article.source === "DAILY_DEAL" ? (
          <></>
        ) : (
          <Container
            sx={{
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url("${article.headerImage}")`,
              display: "flex",
              alignItems: "end",
            }}
          />
        )}
        <Container>
          <article>
            <Typography
              variant="h1"
              sx={{
                fontSize: "3.5em",
                fontFamily: `'Abril Fatface', Abril, "Times New Roman", Times, serif`,
              }}
            >
              {article.title}
            </Typography>
            <Typography variant="subtitle">{article.subtitle}</Typography>
            {article.getArticleHtml(isDarkMode)}
          </article>
        </Container>
        {article.addOns && article.addOns.showDeals ? (
          <Stack alignItems="center" gap={3}>
            <Typography variant="h5">
              Just looking for the best deals? Check out these deals, updated
              daily.
            </Typography>
            <Container>
              <GearDealsContainer gearType={article.gearType} />
            </Container>
            <Typography variant="h6">
              For even more deals, visit{" "}
              <a href="https://www.packwizard.com/gear">Daily Deals</a>
            </Typography>
          </Stack>
        ) : (
          <></>
        )}
      </Stack>
    </Container>
  );
};

export default DynamicArticleGenerator;
