import React from "react";

import {
  Box,
  Button,
  Container,
  Divider,
  Rating,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Collapse,
  Snackbar,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Checkbox,
  Tooltip,
  Link,
} from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { getColorWithMode } from "../../constants/colors";
import { fetchItemForItemDisplay } from "../../api/gear";
import { flagItem } from "../../api/report";
import BrandBuyButton from "./BrandBuyButton";
import PriceHistory from "./PriceHistory";
import { Helmet } from "react-helmet";
import { externalLinkWrapper, getAffiliateKey } from "../../utils/links";
import ShareIcon from "@mui/icons-material/Share";
import MuiAlert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import GearAnalyticsContainer from "./GearAnalyticsContainer";
import ReviewSystemContainer from "../Reviews/ReviewSystemContainer";
import RateReviewIcon from "@mui/icons-material/RateReview";
import momenttz from "moment-timezone";
import GearDealsContainer from "./GearDealsContainer";
import { getUrlForCheapestItem, buildUrl } from "../../utils/gear";

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const getMostRecentPriceUpdate = (retailers) => {
  if (!retailers || !retailers.length) return null;
  let mostRecentDate = 0;
  retailers.forEach(({ lastUpdated }) => {
    if (new Date(lastUpdated) > new Date(mostRecentDate)) {
      mostRecentDate = lastUpdated;
    }
  });
  return mostRecentDate;
};

const ItemDisplay = ({ darkMode, gearType, gearText }) => {
  const { id } = useParams();

  const [item, setItem] = React.useState({});
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1380 });
  const isMobile = useMediaQuery({ maxWidth: 898 });
  const [expandDescription, setExpandDescription] = React.useState(false);
  const [snackbarIsOpen, setSnackbarIsOpen] = React.useState(false);
  const [reportSnackBarIsOpen, setReportSnackBarIsOpen] = React.useState(false);
  const [reportIsOpen, setReportIsOpen] = React.useState(false);
  const [incorrectSpecs, setIncorrectSpecs] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIncorrectSpecs(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleReportSnackBarClose = () => {
    setReportSnackBarIsOpen(false);
  };

  const handleSubmitReport = (itemId, incorrectSpecs) => {
    flagItem(itemId, gearType, incorrectSpecs);
    setReportIsOpen(false);
    setReportSnackBarIsOpen(true);
  };

  const getUrl = (url) => {
    const rawUrl = new URL(url);
    return rawUrl.hostname.split(/\./).slice(-2).join(".");
  };

  const handleSnackbarClose = () => {
    setSnackbarIsOpen(!snackbarIsOpen);
  };

  React.useEffect(() => {
    (async () => {
      const fetchedItem = await fetchItemForItemDisplay(gearType, id);
      setItem(fetchedItem);
    })();
  }, [id, gearType]);

  const handleCopyToClipBoard = () => {
    navigator.clipboard.writeText(window.location.href);
    setSnackbarIsOpen(true);
  };

  const handleJumpToReview = () => {
    const element = document.getElementById("review-system-container");
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const getPrice = (price, lowestPrice) => {
    if (lowestPrice > 0) {
      return lowestPrice.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    }
    return price > 0 || !lowestPrice
      ? price.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })
      : lowestPrice.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
  };

  if (Object.keys(item).length === 0) return <></>;
  const specKeys = item.techSpecs || [].map(({ label }) => label);
  console.log(specKeys);
  const urlToUseForRetailer = getUrlForCheapestItem(item.retailers, item.url);
  const mostRecentDate = getMostRecentPriceUpdate(item.retailers);

  return (
    <>
      <Stack sx={{ width: "100%", height: "100%" }}>
        <Helmet>
          <title>{`${item.formattedBrand} ${item.name} Specs, Retailers, Reviews & Live Pricing`}</title>
          <link rel="canonical" href={`${buildUrl(gearType, id)}`} />

          <meta
            name="keywords"
            content={`${item.brand} ${item.name}, ${item.brand} ${item.name} specs, ${item.brand} ${item.name} weight, ${item.brand} ${item.name} review, ${item.brand} ${item.name} cheapest`}
          />
          <meta
            name="description"
            content={`Up to date weight, specs and live price tracking for retailers for the ${item.formattedBrand} ${item.name}`}
          />
          <meta
            property="og:title"
            content={`${item.formattedBrand} ${item.name}`}
          />
          <meta
            property="og:description"
            content={`Up to date weight, specs and live price tracking for retailers for the ${item.formattedBrand} ${item.name}`}
          />
          <meta
            property="og:image"
            content={item.imageUrl || "/pack_wizard.png"}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={`${item.formattedBrand} ${item.name}`}
          />
          <meta
            name="twitter:description"
            content={`Up to date weight, specs and live price tracking for retailers for the ${item.formattedBrand} ${item.name}`}
          />
          <meta
            name="twitter:image"
            content={item.imageUrl || `/pack_wizard.png`}
          />
        </Helmet>
        <Dialog
          open={reportIsOpen}
          onClose={() => {
            setReportIsOpen(false);
          }}
        >
          <DialogTitle>Report Incorrect Data</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ fontSize: isMobile ? "20px" : "16px" }}>
              Noticed some incorrect data?
            </DialogContentText>
            <DialogContentText
              sx={{
                maxWidth: "400px",
                fontSize: isMobile ? "18px" : "14px",
                marginTop: "5px",
              }}
            >
              With thousands of items in our database, sometimes some specs slip
              through the cracks. Please let us know which spec is incorrect and
              we'll verify and fix it ASAP. Thank you!
            </DialogContentText>
            <FormControl sx={{ m: 1, width: 300, marginTop: "10px" }}>
              <InputLabel>Incorrect Specs (Required)</InputLabel>
              <Select
                multiple
                value={incorrectSpecs}
                required
                onChange={handleChange}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                label="Incorrect Specs (Required)"
              >
                {specKeys.map(({ label }) => (
                  <MenuItem key={label} value={label}>
                    <Checkbox checked={incorrectSpecs.indexOf(label) > -1} />
                    <ListItemText primary={label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setReportIsOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmitReport(item._id, incorrectSpecs);
              }}
              disabled={!incorrectSpecs.length}
            >
              Report Issue
            </Button>
          </DialogActions>
        </Dialog>
        <Container
          sx={{
            width: "100%",
            height: "100%",
            textAlign: "center",
            backgroundColor: getColorWithMode(darkMode, "title"),
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{ width: "100%", paddingTop: "10px" }}
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              size="large"
              sx={{
                width: "100px",
              }}
              startIcon={<ShareIcon />}
              onClick={handleCopyToClipBoard}
            >
              Share
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{
                width: "100px",
                color: "white",
              }}
              color="success"
              startIcon={<RateReviewIcon />}
              onClick={handleJumpToReview}
            >
              Review
            </Button>
          </Stack>
          <Stack
            direction="row"
            justifyContent={"flex-start"}
            sx={{ paddingBottom: "20px" }}
            spacing={1}
          >
            <Box
              sx={{
                marginTop: "10px",
                textAlign: "center",
                backgroundColor: "white",
                width: "300px",
                minWidth: "300px",
                height: "300px",
                overflow: "hidden",
                position: "relative",
                borderRadius: "5px",
              }}
            >
              <img
                fetchpriority="high"
                style={{
                  borderRadius: "5px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  maxHeight: 300,
                  maxWidth: 300,
                  objectFit: "cover",
                  "&:hover": {
                    cursor: "pointer",
                    opacity: "0.8",
                  },
                }}
                alt={`${item.brand} ${item.name}`}
                src={item.imageUrl}
                onClick={() => {
                  externalLinkWrapper(urlToUseForRetailer);
                }}
              />
            </Box>
            <Stack sx={{ paddingTop: "15px" }}>
              <Typography
                variant="h4"
                textAlign="left"
                sx={{
                  fontWeight: "700",
                  fontFamily: "Helvetica",
                  color: "white",
                }}
              >
                {`${item.formattedBrand} ${item.name}`}
              </Typography>
              <Typography
                variant="h6"
                textAlign="left"
                sx={{
                  fontWeight: "700",
                  fontFamily: "Helvetica",
                  color: "white",
                }}
              >
                {item.variant ? `(${item.variant})` : ""}
              </Typography>
              <Rating
                readOnly
                value={item.runningStarRating || item.reviewValue}
                sx={{ paddingTop: "10px" }}
              />
              <Typography
                textAlign="left"
                sx={{
                  fontSize: isMobile ? "24px" : "18px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  color: "white",
                }}
              >
                {getPrice(item.price, item.lowestPrice)}
              </Typography>
              <Box sx={{ maxWidth: "900px" }}>
                {item.description && item.description.length > 0 ? (
                  <>
                    <Collapse collapsedSize={"100px"} in={expandDescription}>
                      <Typography
                        color="white"
                        variant={isDesktopOrLaptop ? "body1" : "h6"}
                        sx={{ padding: "5px" }}
                        textAlign="left"
                      >
                        {item.description}
                      </Typography>
                    </Collapse>
                    <Button
                      align="left"
                      sx={{ color: "lightblue" }}
                      onClick={() => {
                        setExpandDescription(!expandDescription);
                      }}
                    >
                      Expand Description
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Stack>
          </Stack>
        </Container>
        <Stack spacing={1}>
          <Stack direction={isDesktopOrLaptop ? "row" : "column"}>
            <Container
              sx={{
                width: isDesktopOrLaptop ? "50%" : "100%",
                minWidth: "500px",
              }}
            >
              <Typography
                variant={isDesktopOrLaptop ? "h6" : "h5"}
                sx={{
                  height: "20px",
                  paddingLeft: "5px",
                  marginTop: "20px",
                  fontFamily: "Helvetica",
                }}
              >
                Specs
              </Typography>
              <Divider sx={{ paddingTop: "15px" }} />
              <Box textAlign="right">
                <Button
                  size="large"
                  sx={{ width: "120px" }}
                  startIcon={<FlagIcon />}
                  onClick={() => {
                    setReportIsOpen(true);
                  }}
                >
                  Report
                </Button>
              </Box>
              {gearType === "insulatedJacket" ? (
                <Typography
                  fontStyle="italic"
                  variant="subtitle2"
                  color="grey"
                >{`*The following specs are for the ${item.specSize} variant`}</Typography>
              ) : (
                <></>
              )}
              <TableContainer sx={{ marginTop: "5px" }} component={Paper}>
                <Table>
                  <TableBody>
                    {item.techSpecs && item.techSpecs.length ? (
                      item.techSpecs.map(({ label, value }) =>
                        value ? (
                          <TableRow key={label}>
                            <TableCell
                              sx={{
                                fontSize: isDesktopOrLaptop ? "14px" : "18px",
                              }}
                            >
                              {label}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                fontSize: isDesktopOrLaptop ? "14px" : "18px",
                              }}
                            >
                              {value}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <></>
                        )
                      )
                    ) : (
                      <TableRow>
                        <TableCell align="center">No Specs Available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>
            {item.retailers.length === 0 ? (
              <Container
                sx={{
                  width: isDesktopOrLaptop ? "50%" : "100%",
                  minWidth: "500px",
                }}
              >
                <Stack spacing={2}>
                  <Typography
                    variant={isDesktopOrLaptop ? "h6" : "h5"}
                    sx={{
                      height: "20px",
                      paddingLeft: "5px",
                      marginTop: "20px",
                      fontFamily: "Helvetica",
                    }}
                  >
                    Retailers
                  </Typography>
                  <Divider />
                  {getAffiliateKey(item.url) ? (
                    <>
                      <TableContainer
                        sx={{
                          marginTop: "15px",
                          fontSize: isDesktopOrLaptop ? "16px" : "24px",
                        }}
                        component={Paper}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="center"
                                sx={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  fontSize: isDesktopOrLaptop ? "16px" : "20px",
                                }}
                              >
                                Retailer
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  fontSize: isDesktopOrLaptop ? "16px" : "20px",
                                }}
                              >
                                Shipping
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  fontSize: isDesktopOrLaptop ? "16px" : "20px",
                                }}
                              >
                                Price
                              </TableCell>
                              <TableCell
                                sx={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  fontSize: isDesktopOrLaptop ? "16px" : "20px",
                                }}
                              ></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center">
                                <BrandBuyButton
                                  retailer={getAffiliateKey(item.url)}
                                  url={item.url}
                                  darkMode={darkMode}
                                />
                              </TableCell>
                              <TableCell align="center">-</TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontSize: isDesktopOrLaptop ? "16px" : "20px",
                                }}
                              >
                                <Tooltip title="This price may be out of date as we do not capture live pricing data for this item for this retailer">
                                  <Typography>
                                    {Number(item.price) ? (
                                      <Link
                                        onClick={() => {
                                          externalLinkWrapper(item.url);
                                        }}
                                        sx={{
                                          textDecoration: "none",
                                          color: "orange",
                                          "&:hover": {
                                            cursor: "pointer",
                                          },
                                        }}
                                      >
                                        {Number(item.price).toLocaleString(
                                          "en-US",
                                          {
                                            style: "currency",
                                            currency: "USD",
                                          }
                                        )}
                                      </Link>
                                    ) : (
                                      "-"
                                    )}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                              <TableCell>
                                <Button
                                  variant="contained"
                                  size="large"
                                  color="success"
                                  onClick={() => {
                                    externalLinkWrapper(item.url);
                                  }}
                                >
                                  View
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Typography
                        variant={isDesktopOrLaptop ? "h6" : "h5"}
                        sx={{
                          height: "20px",
                          paddingLeft: "5px",
                          marginTop: "20px",
                          fontFamily: "Helvetica",
                        }}
                      >
                        Price History
                      </Typography>
                      <Divider sx={{ paddingTop: "15px" }} />
                      {item.retailers.length > 0 ? (
                        <PriceHistory
                          retailers={item.retailers}
                          darkMode={darkMode}
                        />
                      ) : (
                        <Typography
                          textAlign="center"
                          sx={{ marginTop: "10px" }}
                        >
                          No Pricing History Available
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Paper
                      sx={{
                        textAlign: "center",
                        height: "300px",
                      }}
                      component={Stack}
                      direction="column"
                      justifyContent="center"
                    >
                      <Stack
                        alignItems="center"
                        sx={{ marginTop: "-20px" }}
                        spacing={2}
                      >
                        <Typography
                          sx={{ fontSize: isMobile ? "28px" : "20px" }}
                        >{`We don't yet offer live pricing for this ${gearText}`}</Typography>
                        <Button
                          variant="contained"
                          sx={{ width: "300px" }}
                          onClick={() => {
                            externalLinkWrapper(item.url);
                          }}
                        >
                          View at {getUrl(item.url)}
                        </Button>
                      </Stack>
                    </Paper>
                  )}
                </Stack>
              </Container>
            ) : (
              <Container
                sx={{
                  width: isDesktopOrLaptop ? "50%" : "100%",
                  minWidth: "500px",
                }}
              >
                <Stack spacing={2}>
                  <Typography
                    variant={isDesktopOrLaptop ? "h6" : "h5"}
                    sx={{
                      height: "20px",
                      paddingLeft: "5px",
                      marginTop: "20px",
                      fontFamily: "Helvetica",
                    }}
                  >
                    Retailers
                  </Typography>

                  <Divider />
                  <Stack spacing={0} alignItems="flex-end">
                    {mostRecentDate ? (
                      <Box>
                        <Typography variant="subtitle2" color="grey">
                          {`Last updated: ${momenttz(mostRecentDate)
                            .tz(
                              Intl.DateTimeFormat().resolvedOptions().timeZone
                            )
                            .format("ddd MMM DD YYYY @ hh:mm a zz")}`}
                        </Typography>
                      </Box>
                    ) : (
                      <></>
                    )}
                    {item.retailers.length > 0 ? (
                      <TableContainer
                        sx={{
                          marginTop: "8px",
                          fontSize: isDesktopOrLaptop ? "16px" : "24px",
                        }}
                        component={Paper}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                align="center"
                                sx={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  fontSize: isDesktopOrLaptop ? "16px" : "20px",
                                }}
                              >
                                Retailer
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  fontSize: isDesktopOrLaptop ? "16px" : "20px",
                                }}
                              >
                                Shipping
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  fontSize: isDesktopOrLaptop ? "16px" : "20px",
                                }}
                              >
                                Price
                              </TableCell>
                              <TableCell
                                sx={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  fontSize: isDesktopOrLaptop ? "16px" : "20px",
                                }}
                              ></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {!getAffiliateKey(item.url) ||
                            item.retailers.find(
                              ({ retailer }) =>
                                retailer.toLowerCase() ===
                                getAffiliateKey(item.url)
                            ) ? (
                              <></>
                            ) : (
                              <TableRow>
                                <TableCell align="center">
                                  <BrandBuyButton
                                    retailer={getAffiliateKey(item.url)}
                                    url={item.url}
                                    darkMode={darkMode}
                                  />
                                </TableCell>
                                <TableCell align="center">-</TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: isDesktopOrLaptop
                                      ? "16px"
                                      : "20px",
                                    color: "orange",
                                  }}
                                >
                                  <Tooltip title="This price may be out of date as we do not capture live pricing data for this item for this retailer">
                                    <Typography>
                                      {Number(item.price) ? (
                                        <Link
                                          onClick={() => {
                                            externalLinkWrapper(item.url);
                                          }}
                                          sx={{
                                            textDecoration: "none",
                                            color: "orange",
                                            "&:hover": {
                                              cursor: "pointer",
                                            },
                                          }}
                                        >
                                          {Number(item.price).toLocaleString(
                                            "en-US",
                                            {
                                              style: "currency",
                                              currency: "USD",
                                            }
                                          )}
                                        </Link>
                                      ) : (
                                        "-"
                                      )}
                                    </Typography>
                                  </Tooltip>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    size="large"
                                    color="success"
                                    onClick={() => {
                                      externalLinkWrapper(item.url);
                                    }}
                                  >
                                    View
                                  </Button>
                                </TableCell>
                              </TableRow>
                            )}
                            {item.retailers.map(
                              ({
                                retailer,
                                buyLink,
                                salePrice,
                                retailPrice,
                              }) => (
                                <TableRow>
                                  <TableCell align="center">
                                    <BrandBuyButton
                                      retailer={retailer}
                                      url={buyLink}
                                      darkMode={darkMode}
                                    />
                                  </TableCell>
                                  <TableCell align="center">-</TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: isDesktopOrLaptop
                                        ? "16px"
                                        : "20px",
                                    }}
                                  >
                                    {Number(salePrice) ? (
                                      <Link
                                        onClick={() => {
                                          externalLinkWrapper(item.url);
                                        }}
                                        sx={{
                                          textDecoration: "none",
                                          "&:hover": {
                                            cursor: "pointer",
                                          },
                                        }}
                                      >
                                        <Stack>
                                          {Number(retailPrice) &&
                                          Number(retailPrice) !==
                                            Number(salePrice) ? (
                                            <Typography
                                              variant="subtitle2"
                                              sx={{
                                                textDecoration: "line-through",
                                                color: "grey",
                                              }}
                                            >
                                              {Number(
                                                retailPrice
                                              ).toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                              })}
                                            </Typography>
                                          ) : (
                                            <></>
                                          )}
                                          {Number(salePrice).toLocaleString(
                                            "en-US",
                                            {
                                              style: "currency",
                                              currency: "USD",
                                            }
                                          )}
                                        </Stack>
                                      </Link>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      variant="contained"
                                      size="large"
                                      color="success"
                                      onClick={() => {
                                        externalLinkWrapper(buyLink);
                                      }}
                                    >
                                      View
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Typography textAlign="center" sx={{ marginTop: "10px" }}>
                        No Retailers Found
                      </Typography>
                    )}
                  </Stack>
                  <Typography
                    variant={isDesktopOrLaptop ? "h6" : "h5"}
                    sx={{
                      height: "20px",
                      paddingLeft: "5px",
                      marginTop: "20px",
                      fontFamily: "Helvetica",
                    }}
                  >
                    Price History
                  </Typography>
                  <Divider sx={{ paddingTop: "15px" }} />
                  {item.retailers.length > 0 ? (
                    <PriceHistory
                      retailers={item.retailers}
                      darkMode={darkMode}
                    />
                  ) : (
                    <Typography textAlign="center" sx={{ marginTop: "10px" }}>
                      No Pricing History Available
                    </Typography>
                  )}
                </Stack>
              </Container>
            )}
          </Stack>
          <GearAnalyticsContainer
            gearType={gearType}
            productId={id}
            gearText={gearText}
            darkMode={darkMode}
          />
          <ReviewSystemContainer productId={id} gearType={gearType} />
          <GearDealsContainer gearType={gearType} darkMode={darkMode} />
        </Stack>
      </Stack>
      <Snackbar
        open={snackbarIsOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "300px", color: "white" }}
        >
          Copied to clipboard
        </Alert>
      </Snackbar>
      <Snackbar
        open={reportSnackBarIsOpen}
        autoHideDuration={3000}
        onClose={handleReportSnackBarClose}
      >
        <Alert
          onClose={handleReportSnackBarClose}
          severity="warning"
          sx={{ width: "300px", color: "white" }}
        >
          Thank you for reporting!
        </Alert>
      </Snackbar>
    </>
  );
};

export default ItemDisplay;
